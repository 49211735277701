<template>
  <div class="priceall">
    <div class="homeBack">
      <div class="abouttop">8H Auto Devops</div>
      <div class="abouttip">{{ t("aboutUs.main.title") }}</div>
    </div>
    <div class="main">
      <p>
        {{ t("aboutUs.main.desc") }}
      </p>
      <span class="segmentend"> {{ t("aboutUs.main.desc2") }}</span>
      <div class="about-main-mid"></div>
    </div>
    <img
      class="listimg"
      src="../../assets/imgs/partners.png"
      alt=""
    />
    <div class="main" style="position: relative; bottom: 6rem">
      <span class="topTip">{{ t("aboutUs.values.title") }}</span>
      <div class="topline"></div>
      <div class="moralityList">
        <span>{{ t("aboutUs.values.sincerity") }}</span>
        <span>{{ t("aboutUs.values.vision") }}</span>
        <span>{{ t("aboutUs.values.collaboration") }}</span>
        <span>{{ t("aboutUs.values.selfDrive") }}</span>
      </div>
      <div class="topline"></div>
    </div>
    <div class="lastmsg">
      <div class="flexone">
        <img class="lastimg" src="../../assets/imgs/coicon1.webp" alt="" />
        <span class="msglast">{{ t("aboutUs.cooperations") }}</span>
      </div>
      <div class="about-main-mid" style="position: relative; top: 2rem"></div>
      <div class="listMore">
        <div><img src="../../assets/imgs/co1.png" @click="openWindow('https://www.eolink.com')" alt="" style="width:12rem;height:4rem"></div>
        <div><img src="../../assets/imgs/co2.png" @click="openWindow('http://gitlab.cn')" alt="" style="width:12rem;height:5rem"></div>
        <div><img src="../../assets/imgs/co3.png" @click="openWindow('http://fmeamaster.com')" alt="" style="width:12rem;height:8rem"></div>
        <div><img src="../../assets/imgs/co4.png" @click="openWindow('http://elephant-tech.cn')" alt="" style="width:12rem;height:5rem"></div>
        <div><img src="../../assets/imgs/co5.png" @click="openWindow('http://hcis.com.cn')" alt="" style="width:12rem;height:8rem"></div>
        <div><img src="../../assets/imgs/co6.png" @click="openWindow('https://openoga.com/')" alt="" style="width:12rem;height:12rem"></div>
        <div><img src="../../assets/imgs/co7.png" @click="openWindow('http://www.teststars.cc')" alt="" style="width:12rem;height:4rem"></div>
        <div><img src="../../assets/imgs/co8.png" @click="openWindow('https://www.digiproto.com/')" alt="" style="width:12rem;height:4rem"></div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import useLocale from "@/hooks/useLocale"; // 国际化hook
import { computed } from "@vue/reactivity";
import { useStore } from "vuex";
import { ref } from "vue";

const store = useStore();
const lang = computed(() => store.getters.lang);
const {
  i18n: { t }, // 解构实例用具t 访问语言变量
} = useLocale();

let dialog_visible = ref(false);

function closeDialog() {
  dialog_visible.value = false;
}
function openWindow(url: string){
  window.open(url, "_blank")
}
</script>

<style lang="scss" scoped>
//.h__en{
//  max-width: 75rem;
//  font-size: 3.6rem !important;
//}

//.margin-top__en{
//  margin-top: 8.4rem !important;
//}../../assets/imgs/homeback.png
</style>

