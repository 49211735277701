<template>
  <div style="height: 91vh; padding-left: 3rem">
    <span class="top">{{ t("homepage.cooperation.title") }}</span>
    <div class="line"></div>
    <div class="alllist">
      <div @click="scrollView('demand')">
        <img src="../../assets/imgs/需求管理.png" alt="" />
        <span>{{ t("homepage.cooperation.requirement") }}</span>
      </div>
      <div @click="scrollView('work')">
        <img src="../../assets/imgs/工作项.png" alt="" /><span>{{
          t("homepage.cooperation.workItem")
        }}</span>
      </div>
      <div @click="scrollView('test')">
        <img
          src="../../assets/imgs/关闭.png"
          alt=""
          style="width: 8rem; height: 8rem"
        /><span style="position: relative; bottom: 1rem">{{
          t("homepage.cooperation.test")
        }}</span>
      </div>
      <div @click="scrollView('base')">
        <img src="../../assets/imgs/分组1.png" alt="" /><span>{{
          t("homepage.cooperation.baseline")
        }}</span>
      </div>
      <div @click="scrollView('change')">
        <img
          src="../../assets/imgs/变更记录.png"
          alt=""
          style="width: 7rem; height: 6rem"
        /><span>{{ t("homepage.cooperation.change") }}</span>
      </div>
      <div @click="scrollView('release')">
        <img src="../../assets/imgs/分组.png" alt="" /><span>{{
          t("homepage.cooperation.publish")
        }}</span>
      </div>
      <div @click="scrollView('online')">
        <img src="../../assets/imgs/申报项目评审.png" alt="" /><span>{{
          t("homepage.cooperation.onlineJudge")
        }}</span>
      </div>
      <div @click="scrollView('docu')">
        <img src="../../assets/imgs/icon_使用文档.png" alt="" /><span>{{
          t("homepage.cooperation.document")
        }}</span>
      </div>
      <div @click="scrollView('agile')">
        <img src="../../assets/imgs/看板.png" alt="" /><span>{{
          t("homepage.cooperation.agile")
        }}</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import useLocale from "@/hooks/useLocale"; // 国际化hook
import { computed } from "@vue/reactivity";
import { useStore } from "vuex";

const store = useStore();
const lang = computed(() => store.getters.lang);
const {
  i18n: { t }, // 解构实例用具t 访问语言变量
} = useLocale();
function scrollView(id: string) {
  document.querySelector(`#${id}`)!.scrollIntoView({
    behavior: "smooth",
    block: "start",
  });
  let elementRect = document.querySelector(`#${id}`)!.getBoundingClientRect();
  let offsetRem = 6;
  let offsetPixels =
    offsetRem * parseFloat(getComputedStyle(document.documentElement).fontSize);
  let scrollPosition = window.scrollY + elementRect.top - offsetPixels;
  window.scrollTo({
    top: scrollPosition,
    behavior: "smooth",
  });
}
</script>

<style lang="scss" scoped>
</style>

