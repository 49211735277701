<template>
  <page-template id="gongzuo" has-background>
    <template #header>
      <side-header>
        <template #heading-primary>MappingSpace</template>
        <template #heading-secondary>{{t('homepage.workItem.info')}}</template>
        <template #heading-secondary2>{{t('homepage.workItem.title')}}</template>
        <template #description>{{t('homepage.workItem.desc')}}
          <div class="replace">
            <div class="title">{{ t("homepage.replace") }}</div>
            <div class="li1">
              <div class="li11">Jira</div>
              <div class="li11">Excel</div>
            </div>
            <div class="li2">
              <div class="li22">{{t("homepage.workItem.li1")}}</div>
              <div class="li22">~0</div>
            </div>
          </div>
        </template>
      </side-header>
    </template>
    <template #default>
      <img :src="require(`@/assets/imgs/gongzuomain_${lang}.webp`)" alt="" class="s" :class="{'s__en': lang==='en'}">
    </template>
  </page-template>
</template>

<script setup lang="ts">
import useLocale from '@/hooks/useLocale' // 国际化hook
import { computed } from '@vue/reactivity';
import { useStore} from "vuex";

const store = useStore();
const lang = computed(() => store.getters.lang);
const {
  i18n: { t }, // 解构实例用具t 访问语言变量
} = useLocale()

</script>

<style scoped lang="scss">
.s{
  scale: 1.7 !important;
  transform: translate(-10%, 20%);

  &__en{
    scale: 1.5 !important;
    transform: translate(-10%, 18%);
  }
}
</style>
