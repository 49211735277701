<template>
  <page-template has-background  :right-header="true" id="wendang">
    <template #header>
      <side-header>
        <template #heading-primary>MappingSpace</template>
        <template #heading-secondary>{{t('homepage.document.info')}}</template>
        <template #heading-secondary2>{{t('homepage.document.title')}}</template>
        <template #description>{{t('homepage.document.desc')}}
          <div class="replace right">
            <div class="title" style="margin-left: 23rem;">{{t("homepage.replace")}}</div>
            <div class="li1">
              <div class="li11" style="width: 14rem;">Confluence</div>
              <div class="li11" style="margin-right: 1rem;">{{ t("homepage.document.li1") }}</div>
              <div class="li11" style="width: 10rem;">SVN</div>
            </div>
            <div class="li2">
              <div class="li22" style="width: 14rem;">{{ t("homepage.document.li2") }}</div>
              <div class="li22" style="margin-right: 1rem;">{{ t("homepage.document.li3") }}</div>
              <div class="li22" style="width: 10rem;">{{ t("homepage.document.li4") }}</div>
            </div>
          </div>
        </template>
      </side-header>
    </template>
    <template #default>
      <img style="margin-left: 10rem; min-height: 35vh;" :src="require(`@/assets/imgs/wendangmain_${lang}.webp`)" alt="" class="img" :class="{'img__en': lang==='en'}">
    </template>
  </page-template>
</template>

<script setup lang="ts">
import useLocale from '@/hooks/useLocale' // 国际化hook
import { computed } from '@vue/reactivity';
import { useStore} from "vuex";

const store = useStore();
const lang = computed(() => store.getters.lang);
const {
  i18n: { t }, // 解构实例用具t 访问语言变量
} = useLocale()
</script>

<style scoped lang="scss">
.img{
  transform: translate(-15%, 23%);
  scale: 1.65!important;

  &__en{
    transform: translate(-10%, 23%);
    scale: 1.8 !important;
  }
}
</style>
