<template>
    <functionPage v-if="isshowMinjie" :value="cardDataArr.minjie"></functionPage>
    <functionPage v-if="isshowvModel" :value="cardDataArr.vModel"></functionPage>
    <functionPage v-if="isshowDemand" :value="cardDataArr.demand"></functionPage>
    <functionPage v-if="isshowAI" :value="cardDataArr.ai"></functionPage>
    <functionPage v-if="isshowTest" :value="cardDataArr.test"></functionPage>
    <functionPage v-if="isshowBaseline" :value="cardDataArr.baseLine"></functionPage>

</template>

<script setup lang="ts">
import functionPage from "@/pages/Pc/functionPage.vue";
import { ref, computed,onMounted } from "vue";
import { cardDataArr } from '@/utils/funcData.js'; // 导入数据文件
import { useStore } from "vuex";
import {useRoute} from 'vue-router'
const route = useRoute();
const namePage=ref()
const isshowMinjie = computed(() => namePage.value === 'Agile');
const isshowvModel = computed(() => namePage.value === 'vModel');
const isshowDemand = computed(() => namePage.value === 'Demand');
const isshowAI = computed(() => namePage.value === 'AI');
const isshowTest = computed(() => namePage.value === 'Test');
const isshowBaseline = computed(() => namePage.value === 'Baseline&Change');

 // 在组件第一次加载时执行的逻辑
 onMounted(() => {
      // 获取初始的 pageId
      namePage.value = route.params.pageId;
      
    }
)

</script>

