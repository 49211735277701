<template>
  <div class="side-header">
    <h2 class="heading"><slot name="heading-primary"></slot></h2>
    <h3 class="heading-secondary heading-secondary__primary"><slot name="heading-secondary"></slot></h3>

    <h3 class="heading-tertiary" :class="{'margin__en': lang==='en'}"><slot name="heading-secondary2"></slot></h3>
    <p class="description"><slot name="description"></slot></p>
    <a class="u-btn btn" style="margin-top: 4rem;" href="https://ms.ytdevops.com" target="_blank">{{t('btn.btnTrail')}}</a>
  </div>
</template>

<script setup lang="ts">
import useLocale from '@/hooks/useLocale' // 国际化hook
import { computed } from '@vue/reactivity';
import { useStore} from "vuex";
const store = useStore();
const lang = computed(() => store.getters.lang);
const {
  i18n: { t }, // 解构实例用具t 访问语言变量
} = useLocale()
</script>

<style lang="scss" scoped>
.margin__en{
  margin: 4.4rem 0 2.4rem 0 !important;
}
</style>


