<template>
  <the-nav v-if="isshow"></the-nav>
  <MbTheNav v-if="!isshow"></MbTheNav>
  <router-view></router-view>
  <MbTheFooter v-if="!isshow"></MbTheFooter>
  <the-footer v-if="isshow"></the-footer>
</template>

<script setup lang="ts">
import TheNav from "@/layouts/Pc/TheNav.vue";
import TheFooter from "@/layouts/Pc/TheFooter.vue";
import MbTheNav from "@/layouts/Mobile/MbTheNav.vue";
import MbTheFooter from "@/layouts/Mobile/MbTheFooter.vue";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import useLocale from "@/hooks/useLocale"; // 国际化hook
const router = useRouter();
const isshow = ref(true);
onMounted(() => {
  setTimeout(() => {
    if (_isMobile()) {
      isshow.value = false;
      router.replace(
        router.currentRoute.value.fullPath.indexOf("mb") !== -1
          ? router.currentRoute.value.fullPath
          : "/mb_home"
      );
    } else {
      isshow.value = true;
      router.replace(
        router.currentRoute.value.fullPath.indexOf("mb") === -1
          ? router.currentRoute.value.fullPath
          : "/home"
      );
    }
  });
});
// 判断是否是手机端，如果是，返回true
function _isMobile() {
  return !!navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );
}

const {
  changeLocale, // 修改国际化
} = useLocale();
// 切换语言
let lang;
if (window.location.search.indexOf("lang") !== -1) {
  lang = window.location.search.slice(-2);
  window.localStorage.setItem("locale", lang ? lang : "zh");
  changeLocale(lang);
} else {
  changeLocale(
    localStorage.getItem("lang") ? localStorage.getItem("lang")! : "zh"
  );
}
</script>
