<template>
  <div class="all" id="base">
    <TopDisplaybox
      :showTitle="`${t('homepage.baseline.info')}`"
      :showBottom="`${t('homepage.baseline.title')}`"
      :showText="`${t('homepage.baseline.desc')}`"
    >
      <img src="../../assets/imgs/jixianicon.webp" alt="" />
    </TopDisplaybox>
    <div class="showImgbas">
      <button @click="toMapping" class="btn">{{ t("btn.tryIt") }}</button>
      <img
        src="https://img.js.design/assets/img/6422b395cece21d6b66f15a4.png#f0c5030ad90aa56742878a2536a173ff"
        alt=""
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import useLocale from "@/hooks/useLocale"; // 国际化hook
import { computed } from "@vue/reactivity";
import { useStore } from "vuex";
import TopDisplaybox from "@/components/Mobile/TopDisplaybox.vue";
const store = useStore();
const lang = computed(() => store.getters.lang);
const {
  i18n: { t }, // 解构实例用具t 访问语言变量
} = useLocale();
function toMapping(){
  window.open("https://ms.ytdevops.com")
}
</script>

<style lang="scss" scoped>
</style>

