<template>
    <div class="eolink-page">
        <div class="left" :class="{'left-en': lang === 'en'}">
            <div class="logo">
                <a href="https://www.eolink.com/" target="_blank">
                    <img src="../../assets/imgs/eolinkLogo.png" alt="">
                </a>
            </div>
            <div class="content">
                {{ t("toolchain.partner.eolink.content")}}
            </div>
            <div class="content1">
                {{ t("toolchain.partner.eolink.content1") }}
            </div>
            <ul>
                <li>{{ t("toolchain.partner.eolink.li1")}}</li>
                <li>{{ t("toolchain.partner.eolink.li2")}}</li>
                <li>{{ t("toolchain.partner.eolink.li3")}}</li>
                <li>{{ t("toolchain.partner.eolink.li4")}}</li>
            </ul>
        </div>
        <div class="right">
            <el-carousel :interval="4000" type="card" height="26rem" indicator-position="none" style="width: 60rem;">
                <el-carousel-item>
                    <img src="../../assets/imgs/eolinkImg1.jpeg" alt="" width="100%" height="100%">
                </el-carousel-item>
                <el-carousel-item>
                    <img src="../../assets/imgs/eolinkImg2.jpeg" alt="" width="100%" height="100%">
                </el-carousel-item>
            </el-carousel>
        </div>
    </div>
</template>
<script setup lang="ts">
import useLocale from '@/hooks/useLocale' // 国际化hook
import { computed } from '@vue/reactivity';
import { useStore} from "vuex";

const store = useStore();
const lang = computed(() => store.getters.lang);
const {
  i18n: { t }, // 解构实例用具t 访问语言变量
} = useLocale()
</script>

<style scoped>
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>