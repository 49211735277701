<template>
  <div class="card2">
    <h2 style="font-size: 6.4rem">{{t('aboutUs.values.title')}}</h2>
    <div class="divider" style="margin: 1.2rem 0 2.4rem 0"></div>
    <ul class="item-list">
      <li>{{t('aboutUs.values.sincerity')}}</li>
      <li>{{t('aboutUs.values.collaboration')}}</li>
      <li>{{t('aboutUs.values.vision')}}</li>
      <li>{{t('aboutUs.values.selfDrive')}}</li>
    </ul>
    <div class="divider" style="margin-top: 3.6rem"></div>
  </div>
</template>

<script setup lang="ts">
import useLocale from '@/hooks/useLocale' // 国际化hook
import { computed } from '@vue/reactivity';
import { useStore} from "vuex";
const store = useStore();
const lang = computed(() => store.getters.lang);
const {
  i18n: { t }, // 解构实例用具t 访问语言变量
} = useLocale()
</script>

