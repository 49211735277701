<template>
    <div class="industrial-page2">
        <div class="box">
            <div class="title" :class="{'title-en': lang === 'en'}">
                <p>{{ t("industrialIntelligentManufacturing.industrialPage2.title") }}</p>
            </div>
            <div class="content" :class="{'content-en': lang === 'en'}"> 
                <div class="left">
                    <p>{{ t("industrialIntelligentManufacturing.industrialPage2.li1") }}<br/><br/>
                    {{ t("industrialIntelligentManufacturing.industrialPage2.li2") }}<br/><br/>
                    {{ t("industrialIntelligentManufacturing.industrialPage2.li3") }}</p>
                </div>
                <div class="right">
                    <img src="../../assets/imgs/industrialpage2.png" alt="">
                </div>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import useLocale from '@/hooks/useLocale' // 国际化hook
import { computed } from '@vue/reactivity';
import { useStore} from "vuex";

const store = useStore();
const lang = computed(() => store.getters.lang);
const {
  i18n: { t }, // 解构实例用具t 访问语言变量
} = useLocale()

</script>