<template>
    <section>
       <div class="funBox">
           <div class="funcTop">
               <img :src="props.value.imgUrl0">
               <p class="ft1">{{ t(`funcPage.${props.value.title0}`) }}<span class="ft2">{{ t(`funcPage.${props.value.word0}`) }}</span></p>
               
           </div>
            <div class="titleBar">
               <!-- 标题 -->
            <div class="titles">
                <p><a href="#overview">{{ t('funcPage.barText1') }}</a></p>
                <p><a href="#Best_practices">{{ t('funcPage.barText2') }}</a></p>
                <p><a href="#Tool_support">{{ t('funcPage.barText3') }}</a></p>
                <p><a href="#Client_cases">{{ t('funcPage.barText4') }}</a></p>
                <p><a href="#More">{{ t('funcPage.barText5') }}</a></p>
            </div>

            </div>
            <div  id="overview"></div>
            <div  class="box1">
                <div><p class="p1">{{ t(`funcPage.${props.value.title1}`) }}</p></div>
                <div class="word_img1">
                    <div class="word1">
                        <p style="white-space: pre-line;">
                            {{ t(`funcPage.${props.value.word1}`) }}
                        </p>
                    </div>
                    <div class="img1">
                        <img :src="props.value.imgUrl1">
                    </div>
                </div>
                <div  id="Best_practices"></div>
            </div>

            <div class="box2">
                <div><p class="p2">{{ t(`funcPage.${props.value.title2}`)}}</p></div>
                <div class="word_img2">
                    <div class="wordBox1" v-for="(val,index) in props.value.word2" :key="index">
                        <div class="imgBox1">
                             <img :src="val.wUrl">
                        </div>
                        <p class="pWord1">{{ t(`funcPage.${val.w1}_${index}`) }}</p>
                        <p class="pWord2">{{ t(`funcPage.${val.w2}_${index}`) }}</p>


                       
                    </div>
                </div>
                <div  id="Tool_support"></div>
            </div>

            <div  class="box3">
                <div><p class="p3">{{ t(`funcPage.${props.value.title3}`) }}</p></div>
                <div class="word_img3">
                    <div class="left_img">
                        <img :src="props.value.imgUrl2">
                    </div>
                    <div class="right_word">
                                <p class="rWord" >
                                    {{ t(`funcPage.${props.value.word3}`) }}
                                </p>
                        <div class="trialBtn" @click="gotoTrial">
                            <p>{{ t('funcPage.trial') }}</p>
                        </div>
                    </div>
                </div>
                <div id="Client_cases"></div>
            </div>
       </div>
    </section>
    <page-case-resource>
    </page-case-resource>
</template>

<script setup lang="ts">
import useLocale from '@/hooks/useLocale' // 国际化hook
import { computed } from '@vue/reactivity';
import { useStore } from "vuex";
import { ref,defineProps } from 'vue';
import { useRouter } from 'vue-router';
import PageCaseResource from "@/pages/Pc/PageCaseResource.vue";
const store = useStore();
const lang = computed(() => store.getters.lang);
const {
  i18n: { t }, // 解构实例用具t 访问语言变量
} = useLocale()
 const router = useRouter();
const props = defineProps(['value']);




function gotoTrial() {
    window.open('https://ms.ytdevops.com','_blank')
}


</script>

<style scoped lang="scss">
.content {
  max-width: 20vh; // 设置一个最大宽度
  white-space: pre-wrap; // 超出这个宽度就直接换行
}
.content1 {
  max-width: 70vh; // 设置一个最大宽度
  white-space: pre-wrap; // 超出这个宽度就直接换行
}
.content2 {
  max-width: 80vh; // 设置一个最大宽度
  white-space: pre-wrap; // 超出这个宽度就直接换行
}

</style>
