<template>
  <div class="apply-dialog">
    <div class="dialog-wrapper">
      <div class="right">
        <img
          @click="closeDialog"
          src="../../assets/imgs/closeIcon.svg"
          class="close-icon"
        />
        <div class="form">
          <el-form
            label-position="left"
            ref="ruleFormRef"
            :model="applyForm"
            :rules="rules"
          >
            <el-form-item
              prop="name"
              required
              :label="t('applyDialog.label.name')"
              class="label-font"
              :label-width="lang=='en'?'11rem':'8rem'"
              style="margin-bottom: 1.83rem"
            >
              <el-input
                v-model="applyForm.name"
                :placeholder="t('applyDialog.formInfo.name')"
              ></el-input>
            </el-form-item>

            <el-form-item
              prop="email"
              required
              :label="t('applyDialog.label.email')"
              class="label-font"
              :label-width="lang=='en'?'11rem':'8rem'"
              style="margin-bottom: 1.83rem"
            >
              <el-input
                v-model="applyForm.email"
                type="email"
                :placeholder="t('applyDialog.formInfo.email')"
              ></el-input>
            </el-form-item>

            <el-form-item
              prop="phone"
              required
              :label="t('applyDialog.label.phone')"
              class="label-font"
              :label-width="lang=='en'?'11rem':'8rem'"
              style="margin-bottom: 1.83rem"
            >
              <el-input
                v-model="applyForm.phone"
                :placeholder="t('applyDialog.formInfo.phone')"
              ></el-input>
            </el-form-item>

            <el-form label-position="top">
              <el-form-item :label="t('applyDialog.label.careAbout')" class="label-font">
                <el-input
                  v-model="applyForm.careAbout"
                  type="textarea"
                  rows="6"
                  style="margin-top: 0.83rem; font-size: 1.17rem"
                ></el-input>
              </el-form-item>
            </el-form>
          </el-form>
        </div>

        <div class="dialog-footer">
          <div @click="closeDialog" class="btn-cancle">
            {{ t("applyDialog.cancel") }}
          </div>
          <div @click="sendApply(ruleFormRef)" class="btn-ok">
            {{ t("applyDialog.confirm") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, defineProps, toRefs, defineEmits } from "vue";
import { isEmail, isPhone } from "@/utils/validate";
import { sendEmail } from "@/network/home";
import useLocale from "@/hooks/useLocale"; // 国际化hook
import { computed } from '@vue/reactivity';
import { useStore} from "vuex";

const store = useStore();
const lang = computed(() => store.getters.lang);
const {
  i18n: { t }, // 解构实例用具t 访问语言变量
} = useLocale();

const props = defineProps({
  dialog_visible: Boolean,
});
const { dialog_visible } = toRefs(props);
const emit = defineEmits(["closeDialog"]);

function closeDialog() {
  emit("closeDialog");
  applyForm.name = '';
  applyForm.email = '';
  applyForm.phone = '';
  applyForm.careAbout = '';
}

const ruleFormRef = ref("");
const applyForm = reactive({
  name: "",
  email: "",
  phone: "",
  careAbout: "",
});
const check_email = (rule, value, callback) => {
  let emailRegExp = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
  if (!emailRegExp.test(value)) {
    return false;
  } else {
    let point1 = value.indexOf("@");
    let cop = value.slice(point1).toLowerCase();
    let bidden = [
      "@163.com",
      "@126.com",
      "@qq.com",
      "@gmail.com",
      "@yeah.net",
      "@sina.com",
      "@139.com",
      "@hotmail.com",
      "@189.cn",
      "@outlook.com",
      "@sohu.com",
      "@sina.cn",
      "@vip.163.com",
      "@vip.126.com",
      "@188.com",
      "@live.cn",
      "@yahoo.com",
      "@tom.com",
      "@aliyun.com",
      "@21cn.com",
      "@msn.com",
      "@foxmail.com",
      "@live.com",
      "@letv.com",
    ];
    if (bidden.indexOf(cop) == -1) {
      return true;
    }
    return false;
  }
};
const rules = reactive({
  name: [
    {
      required: true,
      message: t("applyDialog.formInfo.name"),
      trigger: "change",
    },
    {
      required: true,
      message: t("applyDialog.formInfo.name"),
      trigger: "blur",
    },
  ],
  email: [
    {
      required: true,
      message: t("applyDialog.formInfo.email"),
      trigger: "blur",
    },
    {
      validator: check_email,
      min: 2,
      max: 15,
      message: t("applyDialog.formInfo.emailFormatError"),
      trigger: "blur",
    },
  ],
  phone: [
    {
      required: true,
      message: t("applyDialog.formInfo.phone"),
      trigger: "blur",
    },
    {
      pattern: /^1[3456789][0-9]{9}$/,
      min: 2,
      max: 11,
      message: t("applyDialog.formInfo.phoneFormatError"),
      trigger: "blur",
    },
  ],
});

const sendApply = async (formEl) => {
  if (!formEl) return;
  await formEl.validate((valid, fields) => {
    if (valid) {
      const data = {
        name: applyForm.name,
        email: applyForm.email,
        phoneNumber: applyForm.phone,
        concernedFunctions: applyForm.careAbout,
      };
      sendEmail(data)
      .finally(() => {
        setTimeout(() => {
          closeDialog();
        }, 1000);
      });
    }
  });
};
</script>

<style>
.label-font .el-form-item__label{
    font-size: 1.17rem;
    color: #707070;
}
</style>
