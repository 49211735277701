<template>
  <price-page1></price-page1>
  <price-page2></price-page2>
  <compare-page></compare-page>
</template>

<script setup lang="ts">
import PricePage1 from "@/pages/Pc/PricePage1.vue";
import PricePage2 from "@/pages/Pc/PricePage2.vue";
import ComparePage from "@/pages/Pc/ComparePage.vue";
</script>
<script lang="ts">
export default {
  metaInfo: {
    meta: [
      {
        name: 'keywords',
        content: '满足汽车行业ASPICE标准、超强追溯性、高阶思维导图、关联透视图、覆盖度测试、基线管理、基线变更审计、在线评审、实时评审记录',
      },
      {
        name: 'keywords',
        content: 'One-stop R&D management platform compliant with ASPICE standards、Ultra strong、In traceability via mind map、Relationship diagram、Monitoring coverage、Baseline management、Baseline change audit、Online review、Real-time record of review results',
      },
      {
        name: 'description',
        content: 'MappingSpace满足ASPICE标准的一站式研发管理平台，特有多项功能，例如：HARA、FMEA、FTA、TARA、在线架构图绘制、思维导图结构等等。',
      },
      {
        name: 'description',
        content: 'MapingSpace is a one-stop R&D management platform that meets the ASPICE standard,with unique features such as HARA, FMEA, FTA, TARA, online architecture drawing, mind map structure, etc.',
      },
    ],
  }
}
</script>

