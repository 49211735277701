<template>
    <industrial-page1></industrial-page1>
    <industrial-page2></industrial-page2>
</template>
<script setup lang="ts">
import IndustrialPage1 from '@/pages/Pc/IndustrialPage1.vue'
import IndustrialPage2 from '@/pages/Pc/IndustrialPage2.vue'
</script>
  <script lang="ts">
  export default {
    metaInfo: {
      meta: [
        {
          name: 'keywords',
          content: '工业智造领域、ISO 26262、DO-178B/C、DO-254、IEC-60601、EN-5012X、IEC-61800、AI大语言模型、降本增效',
        },
        {
          name: 'keywords',
          content: 'industrial intelligent manufacturing filed、ISO 26262、DO-178B/C、DO-254、IEC-60601、EN-5012X、IEC-61800、AI large-language model、Cost Savings and Improved Efficiency',
        },
        {
          name: 'description',
          content: 'MappingSpace的研发设计理念，起源于汽车行业的功能安全标准ISO 26262，并不断拓展到泛工业化领域，如航空航天（DO-178B/C、DO-254）、医疗器械（IEC-60601）、轨道交通（EN-5012X）、电机设备（IEC-61800）等。',
        },
        {
          name: 'description',
          content: "MappingSpace's R&D and design concepts originated from ISO 26262, the functional safety standard for the automotive industry, and have been expanded to cover a wide range of industrialized fields, such as aerospace (DO-178C, DO-254), medical devices (IEC-60601), rail transportation (EN-5012X), and electrical equipment (IEC-61800), etc.",
        },
      ],
    }
  }
  </script>
  