import { createI18n } from 'vue-i18n'

import ZH from './zh'
import EN from './en'

const message = {
    zh: {
        ...ZH
    },
    en: {
        ...EN
    }
}


const i18n = createI18n({
    legacy: false,
    globalInjection: true,
    locale: 'zh',
    messages: message
})

export default i18n
