<template>
    <div class="industrial-page1">
        <div class="name" :class="{'name-en': lang === 'en'}">{{ t("industrialIntelligentManufacturing.industrialPage1.name") }}</div>
        <div class="content" :class="{'content-en': lang === 'en'}">{{ t("industrialIntelligentManufacturing.industrialPage1.content") }}</div>
        <div class="button">
            <a class="u-btn btn" :class="{'margin-top__en': lang==='en'}" href="https://ms.ytdevops.com" target="_blank" style="margin-right: 2.5rem;">{{t('btn.btnTrail')}}</a>
            <a @click="dialog_visible=true" class="u-btn u-btn--transparent" target="_blank">{{t('btn.demo')}}</a>
        </div>
    </div>
    <apply-dialog v-show="dialog_visible" :dialog_visible="dialog_visible" @close-dialog="closeDialog"></apply-dialog>
</template>
<script setup lang="ts">
import useLocale from '@/hooks/useLocale' // 国际化hook
import { computed } from '@vue/reactivity';
import { useStore} from "vuex";
import ApplyDialog from "@/pages/Pc/ApplyDialog.vue";
import { ref } from 'vue'

const store = useStore();
const lang = computed(() => store.getters.lang);
const {
  i18n: { t }, // 解构实例用具t 访问语言变量
} = useLocale()

let dialog_visible = ref(false)

function closeDialog() {
  dialog_visible.value = false
}
</script>