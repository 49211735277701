<template>
    <div class="compare-page" :class="{'compare-page-en': lang === 'en'}" style="margin-top: 3rem;">
        <div class="box">
            <div class="top">
                <div class="contrast">{{ t("price.priceContrast.contrastname") }}</div>
                <div class="contrast1">Polarion</div>
                <div class="contrast2">Codebeamer</div>
                <img src="../../assets/imgs/mapping-space.png" alt="">
            </div>
            <div class="content">
                <div class="line1"></div>
                <div class="line2"></div>
                <div class="column1">
                    <div class="function">{{ t("price.priceContrast.function") }}</div>
                    <div class="ease" >{{ t("price.priceContrast.ease") }}</div>
                    <div class="price">{{ t("price.priceContrast.price") }}</div>
                </div>
                <!-- 功能介绍 -->
                <div class="column2 column">
                    <div class="li">
                        <div class="li1">{{ t("price.priceContrast.contrast.li1") }}</div>
                        <div class="li2">{{ t("price.priceContrast.contrast.li2") }}</div>
                        <div class="li3">{{ t("price.priceContrast.contrast.li3") }}</div>
                        <div class="li4">{{ t("price.priceContrast.contrast.li4") }}</div>
                        <div class="li5">{{ t("price.priceContrast.contrast.li5") }}</div>
                        <div class="li6">{{ t("price.priceContrast.contrast.li6") }}</div>
                        <div class="li7">{{ t("price.priceContrast.contrast.li7") }}</div>
                        <div class="li8">{{ t("price.priceContrast.contrast.li8") }}</div>
                        <div class="li9">{{ t("price.priceContrast.contrast.li9") }}</div>
                        <div class="li0">{{ t("price.priceContrast.contrast.li10") }}</div>
                        <div>HARA</div>
                        <div>FMEA</div>
                        <div>FTA</div>
                        <div>TARA</div>
                    </div>
                    <div class="li11">{{ t("price.priceContrast.contrast.li13") }}</div>
                    <div class="li12">{{ t("price.priceContrast.contrast.li14") }}</div>
                    <div class="bPrice">
                        <div class="">{{ t("price.priceContrast.contrast.li11") }}</div>
                        <div class="" style=" white-space: pre-wrap;">{{ t("price.priceContrast.contrast.li15") }}</div>
                        <div class="">{{ t("price.priceContrast.contrast.li16") }}</div>
                    </div>
                </div>
                <!-- 最左侧 -->
                <div class="column3 column">
                    <div class="li">
                        <img src="../../assets/imgs/error1.png" alt="">
                        <img src="../../assets/imgs/yes1.png" alt="">
                        <img src="../../assets/imgs/error1.png" alt="">
                        <img src="../../assets/imgs/error1.png" alt="">
                        <img src="../../assets/imgs/yes1.png" alt="">
                        <img src="../../assets/imgs/error1.png" alt="">
                        <img src="../../assets/imgs/yes1.png" alt="">
                        <img src="../../assets/imgs/yes1.png" alt="">
                        <div class="li9">{{ t("price.priceContrast.polarion.li1") }}</div>
                        <img src="../../assets/imgs/yes1.png" alt="">
                        <img src="../../assets/imgs/error1.png" alt="">
                        <img src="../../assets/imgs/error1.png" alt="">
                        <img src="../../assets/imgs/error1.png" alt="">
                        <img src="../../assets/imgs/error1.png" alt="">
                    </div>
                    <div class="li11">{{ t("price.priceContrast.polarion.li4") }}</div>
                    <div class="li12">{{ t("price.priceContrast.polarion.li5") }}</div>
                    <div class="li13">{{ t("price.priceContrast.polarion.li2") }}</div>
                    <div class="li14">{{ t("price.priceContrast.polarion.li6") }}</div>
                    <div class="li15">{{ t("price.priceContrast.polarion.li7") }}</div>


                </div>
                <!-- 中间 -->
                <div class="column4 column">
                    <div class="li">
                        <img  style="margin-top: 0.1rem;" src="../../assets/imgs/error1.png" alt="">
                        <img  :class="icons" src="../../assets/imgs/yes1.png" alt="">
                        <img :class="icons" src="../../assets/imgs/error1.png" alt="">
                        <img :class="icons" src="../../assets/imgs/error1.png" alt="">
                        <img  :class="icons" src="../../assets/imgs/yes1.png" alt="">
                        <img  :class="icons" src="../../assets/imgs/yes1.png" alt="">
                        <img  :class="icons" src="../../assets/imgs/yes1.png" alt="">
                        <img  :class="icons" src="../../assets/imgs/yes1.png" alt="">
                        <div  class="li9">{{ t("price.priceContrast.polarion.li1") }}</div>
                        <img  :class="icons" src="../../assets/imgs/yes1.png" alt="">
                        <div  class="li9">{{ t("price.priceContrast.Codebeamer.li6") }}</div>
                        <div class="li9">{{ t("price.priceContrast.Codebeamer.li6") }}</div>
                        <img src="../../assets/imgs/error1.png" alt="">
                        <img :class="icons" src="../../assets/imgs/error1.png" alt="">
                    </div>
                    <div class="li11">{{ t("price.priceContrast.Codebeamer.li7") }}</div>
                    <div class="li12">{{ t("price.priceContrast.Codebeamer.li8") }}</div>
                    <div class="li13">{{ t("price.priceContrast.Codebeamer.li4") }}</div>
                    <div class="li14">{{ t("price.priceContrast.Codebeamer.li9") }}</div>
                    <div class="li15">{{ t("price.priceContrast.Codebeamer.li10") }}</div>

                </div>
                <!-- 最右侧 -->
                <div class="column5 column">
                    <div class="li">
                        <img src="../../assets/imgs/yes1.png" alt="">
                        <img src="../../assets/imgs/yes1.png" alt="">
                        <img src="../../assets/imgs/yes1.png" alt="">
                        <img src="../../assets/imgs/yes1.png" alt="">
                        <img src="../../assets/imgs/yes1.png" alt="">
                        <img src="../../assets/imgs/yes1.png" alt="">
                        <img src="../../assets/imgs/yes1.png" alt="">
                        <img src="../../assets/imgs/yes1.png" alt="">
                        <img src="../../assets/imgs/yes1.png" alt="">
                        <img src="../../assets/imgs/yes1.png" alt="">
                        <img src="../../assets/imgs/yes1.png" alt="">
                        <img src="../../assets/imgs/yes1.png" alt="">
                        <img src="../../assets/imgs/yes1.png" alt="">
                        <img src="../../assets/imgs/yes1.png" alt="">
                    </div>
                    <div class="li11">{{ t("price.priceContrast.mappingSpace.li3") }}</div>
                    <div class="li12">{{ t("price.priceContrast.mappingSpace.li4") }}</div>
                    <div class="li13">{{ t("price.priceContrast.mappingSpace.li1") }}</div>
                    <div class="li14" style=" white-space: pre-wrap;">{{ t("price.priceContrast.mappingSpace.li5") }}</div>
                    <div class="li15">{{ t("price.priceContrast.mappingSpace.li6") }}</div>


                </div>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import useLocale from "@/hooks/useLocale"; // 国际化hook
import {computed} from "@vue/reactivity";
import {useStore} from "vuex";

const store = useStore();
const lang = computed(() => store.getters.lang);
const {
  i18n: {t} // 解构实例用具t 访问语言变量
} = useLocale();
const icons = computed(() => {
    if (lang.value === 'zh') return 'icon'
    else return ''
})


</script>