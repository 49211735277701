<template>
  <div class="footerall" id="last">
    <div class="relation">
      <div class="serve">{{ t("footer.contact.service") }}</div>
      <div>
        <span class="num"
          ><span style="color: rgb(155, 156, 157)">{{
            t("footer.contact.china")
          }}</span
          >&ensp; +86 138 1743 8496</span
        >
      </div>
      <div>
        <span class="num"
          ><span style="color: rgb(155, 156, 157)">{{
            t("footer.contact.overseas")
          }}</span
          >&ensp; +65 9730 3300</span
        >
      </div>
      <div style="color: rgb(155, 156, 157); font-size: 16px">
        support@ytdevops.com
      </div>
    </div>
    <div class="backall">
      <div class="yunicon">
        <img src="../../assets/imgs/yuntiicon.png" alt="" />
      </div>
      <div class="name">{{ t("footer.main.title") }}</div>
      <div class="desc">8H Auto Devops</div>
      <div class="last">{{ t("homepage.Jumbotron.word2") }}</div>
    </div>
    <div class="QRcode">
      <button @click="toMapping">{{ t("btn.tryIt") }}</button>
      <div class="code">
        <img
          src="https://img.js.design/assets/img/6425419332c9a9f54152d7e7.jpg#e5a7405089e75f56f7d886ddd8711e33"
          alt=""
        />
      </div>
      <div class="tip">
        {{ t("homepage.Jumbotron.word3") }}
      </div>
    </div>
    <img
      @click="scrollToTop"
      class="toTop"
      src="../../assets/imgs/top.png"
      alt=""
    />
    <div class="tool-box" @click="toTool">
      <img src="../../assets/imgs/toolbox.png" alt="" />
    </div>
    <div class="trademark">
      <div class="markone">
        <div class="get">{{ t("footer.main.right") }}</div>
        <div class="com">{{ t("footer.main.company") }}</div>
      </div>
      <div class="marktwo">
        <div class="get">{{ t("footer.main.icp") }}</div>
        <div class="imgdiv">
          <img src="../../assets/imgs/beian.png" alt="" />
          <div class="com">{{ t("footer.main.record") }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import useLocale from "@/hooks/useLocale"; // 国际化hook
import { computed } from "@vue/reactivity";
import { useStore } from "vuex";
const store = useStore();
const lang = computed(() => store.getters.lang);
const {
  i18n: { t }, // 解构实例用具t 访问语言变量
} = useLocale();
function toMapping() {
  window.open("https://ms.ytdevops.com");
}
function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}
function toTool() {
  window.open(
    "https://tools.ytdevops.com/?lang=" +
      (window.localStorage.getItem("lang")
        ? window.localStorage.getItem("lang")
        : "zh")
  );
}
</script>

<style lang="scss" scoped>
.padding__en {
  padding: 0.6rem 1.2rem;
}
.font__en {
  font-size: 1.4rem;
}
</style>
