import request from "@/network/request";

//发邮件
export function sendEmail(params) {
    return request({
        url: `/file-manage-service/client/sendDemoRequest`,
        method: "post",
        headers: {},
        data: params
    });
}