<template>
  <section class="video-page u-bg-primary">
    <div class="video-page__container" style="margin-top: 9rem;">
      <el-carousel type="card" height="65vh" :autoplay="false">
      <el-carousel-item >
        <iframe height="100%" width="100%" style="border-radius: 1.2rem;" src="//player.bilibili.com/player.html?aid=834404351&bvid=BV1T34y1F7EP&cid=1358749094&p=1"  muted=”true“ scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true"> </iframe>
      </el-carousel-item>
      <el-carousel-item>
        <iframe height="100%" width="100%" style="border-radius: 1.2rem;" src="//player.bilibili.com/player.html?aid=903568032&bvid=BV1GP4y1X7Dx&cid=917689031&p=1"  muted=”true“ scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true"> </iframe>
      </el-carousel-item>
      <el-carousel-item>
        <iframe height="100%" width="100%" style="border-radius: 1.2rem;" src="//player.bilibili.com/player.html?aid=514917972&bvid=BV1hg411D7V8&cid=814675764&page=1" muted=”true“ scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true"> </iframe>
      </el-carousel-item>
    </el-carousel>
    </div>
  </section>
</template>

<script setup lang="ts">

</script>