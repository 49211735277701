<template>
    <section>
        <div :class="props.message">
            <p>{{ t('btn.learn') }}</p>
        </div>
    </section>

</template>

<script setup lang="ts">
import useLocale from '@/hooks/useLocale' // 国际化hook
import { computed } from '@vue/reactivity';
import { useStore } from "vuex";
import { ref,defineProps } from 'vue';
import { useRouter } from 'vue-router';
const store = useStore();
const lang = computed(() => store.getters.lang);
const {
  i18n: { t }, // 解构实例用具t 访问语言变量
} = useLocale()
 const router = useRouter();
// 接受父组件传递的信息
const props = defineProps(['message']);

</script>

<style scoped lang="scss">

</style>
