<template>
    <information-security-page1></information-security-page1>
</template>
<script setup lang="ts">
import InformationSecurityPage1 from '@/pages/Pc/InformationSecurityPage1.vue';
</script>
<script lang="ts">
export default {
  metaInfo: {
    meta: [
      {
        name: 'keywords',
        content: 'TARA、WP.29-R155、思维导图、SFOP、快速导出TARA分析全局清单、ISO21434、信息安全'
      },
      {
        name: 'keywords',
        content: 'TARA、WP.29-R155、Mind mapping、SFOP、Export TARA analytics table quickly、ISO21434、Cyber Security'
      },
      {
        name: 'description',
        content: 'MappingSpace可以提供威胁分析与风险评估，满足WP.29-R155信息安全法规，基于思维导图快速识别信息安全资产。',
      },
      {
        name: 'description',
        content: 'MappingSpace can provide Threat Analysis and Risk Assessment,Compliance with WP.29-R155 Cyber Security Regulations,Rapid identification of Cyber security assets based on mind mapping.',
      },
    ],
  }
}
</script>