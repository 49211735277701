<template>
  <MbHomePage></MbHomePage>
  <MbCooperationPage></MbCooperationPage>
  <MbDemandManagement></MbDemandManagement>
  <MbWorkManagement></MbWorkManagement>
  <MbTestManagement></MbTestManagement>
  <MbBaselineManagement></MbBaselineManagement>
  <MbChangeManagement></MbChangeManagement>
  <MbReleaseManagement></MbReleaseManagement>
  <MbOnlineReview></MbOnlineReview>
  <MbDocumentManagement></MbDocumentManagement>
  <MbAgileKanban></MbAgileKanban>
</template>

<script setup lang="ts">
import MbHomePage from "@/pages/Mobile/MbHomePage.vue";
import MbWorkManagement from "@/pages/Mobile/MbWorkManagement.vue";
import MbDemandManagement from "@/pages/Mobile/MbDemandManagement.vue";
import MbCooperationPage from "@/pages/Mobile/MbCooperationPage.vue";
import MbTestManagement from "@/pages/Mobile/MbTestManagement.vue";
import MbBaselineManagement from "@/pages/Mobile/MbBaselineManagement.vue";
import MbChangeManagement from "@/pages/Mobile/MbChangeManagement .vue";
import MbReleaseManagement from "@/pages/Mobile/MbReleaseManagement .vue";
import MbOnlineReview from "@/pages/Mobile/MbOnlineReview.vue";
import MbDocumentManagement from "@/pages/Mobile/MbDocumentManagement .vue";
import MbAgileKanban from "@/pages/Mobile/MbAgileKanban.vue";
</script>
<script lang="ts">
export default {
  metaInfo: {
    meta: [
    {
        name: "keywords",
        content: "敏捷开发、V模型、需求管理、工作项管理、测试管理、基线管理、变更管理、发布管理、在线评审管理、文档管理、敏捷看板管理",
      },
      {
        name: "keywords",
        content: "Agile development、V-model、Requirement management、Task management、Test management、Baseline management、Change management、Publish management、Online review、Document management、Scrum & Kanban",
      },
      {
        name: "description",
        content: "MappingSpace是一站式需求、开发、测试管理平台，拥有九大管理场景，对敏捷开发流程支持和对V模型框架支持。",
      },
      {
        name: "description",
        content: "MappingSpace is a one-stop requirements,development,and testing management platform with nine management scenarios,supporting agile development processes and the V-model framework.",
      },
    ],
  }
}
</script>