<template>
    <customer-page1 :message="valuePage1"></customer-page1>
    <customer-page2 :message="valuePage2"></customer-page2>
    <customer-page3 :message="valuePage3"></customer-page3>
    <case-change-card :message="valueCard"></case-change-card>
</template>

<script setup lang="ts">
import CustomerPage1 from '@/pages/Pc/CustomerPage1.vue'
import CustomerPage2 from '@/pages/Pc/CustomerPage2.vue'
import CustomerPage3 from '@/pages/Pc/CustomerPage3.vue'
import caseChangeCard from '@/pages/Pc/caseChangeCard.vue'
import {useRoute} from 'vue-router'
import {ref,watch,onMounted} from 'vue'
const route = useRoute();
// 用来判断是哪个合作伙伴
const card = ref()

// customer-page1组件内的值
const transferValuePage1 = {
    aotecar: {
        imgLogo: '/img/carIndustry1.5ad88b50.png',
        // imgLogo: '@/assets/imgs/carIndustry1.png',
        title: 'client.customerPage1.title.aotecar',
        content:  'client.customerPage1.content.aotecar',
        imghome: '/img/aotecarhome.dfe367b5.png',
        href: 'http://www.aotecar.com/',
    },
    TongyuAutomobile: {
        imgLogo: '/img/carIndustry2.e3f0b480.png',
        // imgLogo: '@/assets/imgs/carIndustry2.png',
        title: 'client.customerPage1.title.TongyuAutomobile',
        content:  'client.customerPage1.content.TongyuAutomobile',
        imghome: '/img/TongyuAutomobilehome.8db0e5bf.png',
        href: 'http://www.tongyuauto.com/',
    },
    Fuerda: {
        imgLogo: '../../assets/imgs/carIndustry5.png',
        // imgLogo: '@/assets/imgs/carIndustry5.png',
        title: 'client.customerPage1.title.Fuerda',
        content: 'client.customerPage1.content.Fuerda',
        imghome: '/img/Fuerdahome.54dda46e.png',
        href: 'https://www.fuerda-china.com/',
    }
}

// 用来传递哪个合作伙伴的customer-page1组件值
const valuePage1 = ref({}); 

// customer-page2组件内的值
const transferValuePage2 = {
    aotecar: {
        title: 'client.customerPage2.title.aotecar',
        content1: 'client.customerPage2.content1.aotecar',
        content2: 'client.customerPage2.content2.aotecar',
        content3: 'client.customerPage2.content3.aotecar',
        card1content1: 'client.customerPage2.card1.content1.aotecar',
        card1content2: 'client.customerPage2.card1.content2.aotecar',
        card2content1: 'client.customerPage2.card2.content1.aotecar',
        card2content2: 'client.customerPage2.card2.content2.aotecar',
        card3content1: 'client.customerPage2.card3.content1.aotecar',
        card3content2: 'client.customerPage2.card3.content2.aotecar',
    },
    TongyuAutomobile: {
        title: 'client.customerPage2.title.TongyuAutomobile',
        content1: 'client.customerPage2.content1.TongyuAutomobile',
        content2: 'client.customerPage2.content2.TongyuAutomobile',
        content3: 'client.customerPage2.content3.TongyuAutomobile',
        card1content1: 'client.customerPage2.card1.content1.TongyuAutomobile',
        card1content2: 'client.customerPage2.card1.content2.TongyuAutomobile',
        card2content1: 'client.customerPage2.card2.content1.TongyuAutomobile',
        card2content2: 'client.customerPage2.card2.content2.TongyuAutomobile',
        card3content1: 'client.customerPage2.card3.content1.TongyuAutomobile',
        card3content2: 'client.customerPage2.card3.content2.TongyuAutomobile',
    },
    Fuerda: {
        title: 'client.customerPage2.title.Fuerda',
        content1: 'client.customerPage2.content1.Fuerda',
        content2: 'client.customerPage2.content2.Fuerda',
        content3: 'client.customerPage2.content3.Fuerda',
        card1content1: 'client.customerPage2.card1.content1.Fuerda',
        card1content2: 'client.customerPage2.card1.content2.Fuerda',
        card2content1: 'client.customerPage2.card2.content1.Fuerda',
        card2content2: 'client.customerPage2.card2.content2.Fuerda',
        card3content1: 'client.customerPage2.card3.content1.Fuerda',
        card3content2: 'client.customerPage2.card3.content2.Fuerda',
    }
}

// 用来传递哪个合作伙伴的customer-page2组件值
const valuePage2 = ref({}); 

// customer-page3组件内的值
const transferValuePage3 = {
    aotecar: {
        card1title: 'client.customerPage3.card1.title.aotecar',
        card1content: 'client.customerPage3.card1.content.aotecar',
        card1li1: 'client.customerPage3.card1.li1.aotecar',
        card1li2: 'client.customerPage3.card1.li2.aotecar',
        card1li3: 'client.customerPage3.card1.li3.aotecar',
        card1li4: 'client.customerPage3.card1.li4.aotecar',
        card2title: 'client.customerPage3.card2.title.aotecar',
        card2content: 'client.customerPage3.card2.content.aotecar',
        card2li1: 'client.customerPage3.card2.li1.aotecar',
        card2li2: 'client.customerPage3.card2.li2.aotecar',
        card3title: 'client.customerPage3.card3.title.aotecar',
        card3content: 'client.customerPage3.card3.content.aotecar',
        card3li1: 'client.customerPage3.card3.li1.aotecar',
        card3li2: 'client.customerPage3.card3.li2.aotecar',
        card3li3: 'client.customerPage3.card3.li3.aotecar',
        imgli11: '../../assets/imgs/aotecarli11.jpeg',
    },
    TongyuAutomobile: {
        card1title: 'client.customerPage3.card1.title.TongyuAutomobile',
        card1content: 'client.customerPage3.card1.content.TongyuAutomobile',
        card1li1: 'client.customerPage3.card1.li1.TongyuAutomobile',
        card1li2: 'client.customerPage3.card1.li2.TongyuAutomobile',
        card2title: 'client.customerPage3.card2.title.TongyuAutomobile',
        card2content: 'client.customerPage3.card2.content.TongyuAutomobile',
        card2li1: 'client.customerPage3.card2.li1.TongyuAutomobile',
        card2li2: 'client.customerPage3.card2.li2.TongyuAutomobile',
        card3title: 'client.customerPage3.card3.title.TongyuAutomobile',
        card3content: 'client.customerPage3.card3.content.TongyuAutomobile',
        card3li1: 'client.customerPage3.card3.li1.TongyuAutomobile',
        card3li2: 'client.customerPage3.card3.li2.TongyuAutomobile',
    },
    Fuerda: {
        card1title: 'client.customerPage3.card1.title.Fuerda',
        card1content: 'client.customerPage3.card1.content.Fuerda',
        card1li1: 'client.customerPage3.card1.li1.Fuerda',
        card1li2: 'client.customerPage3.card1.li2.Fuerda',
        card1li3: 'client.customerPage3.card1.li3.Fuerda',
        card2title: 'client.customerPage3.card2.title.Fuerda',
        card2content: 'client.customerPage3.card2.content.Fuerda',
        card2li1: 'client.customerPage3.card2.li1.Fuerda',
        card2li2: 'client.customerPage3.card2.li2.Fuerda',
        card3title: 'client.customerPage3.card3.title.Fuerda',
        card3content: 'client.customerPage3.card3.content.Fuerda',
        card3li1: 'client.customerPage3.card3.li1.Fuerda',
        card3li2: 'client.customerPage3.card3.li2.Fuerda',
        card3li3: 'client.customerPage3.card3.li3.Fuerda',
        contentli1: 'client.customerPage3.card1.content.li1',
        contentli2: 'client.customerPage3.card1.content.li2',
        contentli3: 'client.customerPage3.card1.content.li3',
    }
}

// 用来传递哪个合作伙伴的customer-page3组件值
const valuePage3 = ref({}); 

const valueCard=ref()

// 用来传递哪些值
const transferValue = () => {
    if(card.value === 'aotecar') {
        valuePage1.value = transferValuePage1.aotecar;
        valuePage2.value = transferValuePage2.aotecar;
        valuePage3.value = transferValuePage3.aotecar;
        valueCard.value='aotecar'
    }else if(card.value === 'TongyuAutomobile') {
        valuePage1.value = transferValuePage1.TongyuAutomobile;
        valuePage2.value = transferValuePage2.TongyuAutomobile;
        valuePage3.value = transferValuePage3.TongyuAutomobile;
         valueCard.value='TongyuAutomobile'
    }else if(card.value === 'Fuerda'){
        valuePage1.value = transferValuePage1.Fuerda;
        valuePage2.value = transferValuePage2.Fuerda;
        valuePage3.value = transferValuePage3.Fuerda;
         valueCard.value='Fuerda'
    }
}

// 监视参数的变化
watch(() => route.params.pageId,(newPageId: any) => {
        // 在路由参数发生变化时执行的逻辑
        // 例如，可以重新加载数据或执行其他操作
        loadData(newPageId);
    }
)

// 参数变化时，需要做的事
const loadData = (newPageId: any) => {
    card.value = newPageId;
    transferValue();
}

 // 在组件第一次加载时执行的逻辑
 onMounted(() => {
      // 获取初始的 pageId
      const initialPageId = route.params.pageId;
      // 执行初始加载逻辑
      loadData(initialPageId);
    }
)
</script>
<script lang="ts">
export default {
  metaInfo: {
    meta: [
      {
        name: 'keywords',
        content: '奥特佳、同驭汽车、福尔达、思维导图式需求管理、多人实时评审、随时查看需求修改历史记录、Word Excel批量导入、支持HARA、FTA和DFMEA等功能安全分析方法、项目复用及追溯性继承、敏捷与ASPICE融合、甘特图工时统计'
      },
      {
        name: 'keywords',
        content: 'Aotecar、Tongyu Automotive、Fuerda、Mind mapping requirements management、Multiuser real-time evaluation、View requirements modification history at any time、Batch import of Word and Excel、Supporting HARA, FTA, and DFMEA for functional safety analysis methods、Project reuse and traceability inheritance、Integrating agile development with ASPICE、Gantt chart hour statistics'
      },
      {
        name: 'description',
        content: '云体科技拥有众多合作客户，为客户的需求开发和开发速度质量提供大力的支持。',
      },
      {
        name: 'description',
        content: 'Yunti Technology has numerous cooperative clients and provides strong support for the development speed and quality of their needs.',
      },
    ],
  }
}
</script>