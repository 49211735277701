<template>
    <page-template id="xuqiu">
        <template #header>
            <div class="hara-header" style="padding-top: 8rem;">
                <side-header>
                    <template #heading-primary>MappingSpace</template>
                    <template #heading-secondary>{{ t('informationSecurity.title') }}</template>
                    <template #heading-secondary2>{{ t('informationSecurity.title1') }}</template>
                    <template #description>
                       <div style="margin-left: 3rem; width: 60rem;margin-top: 3rem;">
                            <ul>
                                <li><p>{{ t('informationSecurity.li1') }}</p></li>
                                <li><p>{{ t('informationSecurity.li2') }}</p></li>
                                <li><p>{{ t('informationSecurity.li3') }}</p></li>
                                <li><p>{{ t('informationSecurity.li4') }}</p></li>
                            </ul>   
                       </div>
                    </template>
                </side-header>
            </div>
        </template>
        <template #default>
            <img :src="require(`@/assets/imgs/securityImg_${lang}.png`)" alt="" style="scale: 1;margin-left: 26rem;margin-top: -15rem; width: 49rem;" class="hara-img"
                :class="{ 's__en': lang === 'en' }">
        </template>
    </page-template>
</template>

<script setup lang="ts">
import useLocale from '@/hooks/useLocale' // 国际化hook
import { computed } from '@vue/reactivity';
import { useStore } from "vuex";
const store = useStore();
const lang = computed(() => store.getters.lang);
const {
    i18n: { t }, // 解构实例用具t 访问语言变量
} = useLocale()
</script>

<style scoped>

.hara-header{
    margin-top: 3rem;
    height: 100vh;
}

.hara-img {
    scale: 1.3 !important;
    transform: translate(-30%, 65%);
    z-index: -100;

    &__en {
        transform: translate(-19%, 26%);
    }
}
</style>