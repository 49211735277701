<template>
  <div
    style="
      height: 116rem;
      display: flex;
      flex-direction: column;
      align-items: center;
    "
  >
    <div class="homeBack">
      <img
        src="https://img.js.design/assets/img/6422a2494e8874d2ce347d57.png#19e5f35ba5498a34d8b3aba750e56a03"
        alt=""
      />
      <div class="text">Agile · ASPICE · AI</div>
    </div>
    <img class="homemid" src="https://img.js.design/assets/img/6424f25cd812d934031fca59.png#f918321e2066626187853313a78b778d"/>
    <div class="h_btn">
      <button class="btno" @click="toMapping">{{ t("btn.tryIt") }}</button>
    </div>
    <div class="h_btn">
      <button class="btnt" @click="dialog_visible=true">{{ t("btn.demo") }}</button>
    </div>
    <div class="last_foot">{{ t("homepage.Jumbotron.word2") }}</div>
  </div>
   <MbApplyDialog v-show="dialog_visible" :dialog_visible="dialog_visible" @close-dialog="closeDialog"></MbApplyDialog>
</template>

<script setup lang="ts">
import useLocale from "@/hooks/useLocale"; // 国际化hook
import MbApplyDialog from "@/components/Mobile/MbApplydialog.vue";
import { computed } from "@vue/reactivity";
import { useStore } from "vuex";
import { ref } from "vue";
const store = useStore();
const lang = computed(() => store.getters.lang);
const {
  i18n: { t }, // 解构实例用具t 访问语言变量
} = useLocale();

let dialog_visible = ref(false);
function toKnowledge() {
  window.open(
    "https://ms.ytdevops.com/pubKnowledge/1db7562f-6f55-4d30-907c-390163fe43b8"
  );
}
function toMapping() {
  window.open("https://ms.ytdevops.com");
}
function closeDialog() {
  dialog_visible.value = false
}
</script>

<style lang="scss" scoped>
//.h__en{
//  max-width: 75rem;
//  font-size: 3.6rem !important;
//}

//.margin-top__en{
//  margin-top: 8.4rem !important;
//}../../assets/imgs/homeback.png
</style>

