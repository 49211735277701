<template>
 <page-template  id="biangeng">
   <template #header>
     <side-header>
       <template #heading-primary>MappingSpace</template>
       <template #heading-secondary>
          <div class="pageBtn">
            {{t('homepage.change.info')}}
            <PageMore @click="gotoChange" class="pMore" :message="'moreBox'"></PageMore>
          </div>
       </template>
       <template #heading-secondary2>{{t('homepage.change.title')}}</template>
       <template #description>{{t('homepage.change.desc')}}
          <div class="replace">
            <div class="title">{{ t("homepage.replace") }}</div>
            <div class="li1">
              <div class="li11">Polarion</div>
              <div class="li11">IBM Doors</div>
              <div class="li11">Excel</div>
            </div>
            <div class="li2">
              <div class="li22">{{ t("homepage.change.li1") }}</div>
              <div class="li22">{{ t("homepage.change.li1") }}</div>
              <div class="li22">~0</div>
            </div>
          </div>
      </template>
     </side-header>
   </template>
   <template #default>
     <img :src="require(`@/assets/imgs/biangengmain_${lang}.webp`)" alt="" class="img" :class="{'img__en': lang==='en'}">
   </template>
 </page-template>
</template>

<script setup lang="ts">
import useLocale from '@/hooks/useLocale' // 国际化hook
import { computed } from '@vue/reactivity';
import { useStore} from "vuex";
import PageMore from '@/pages/Pc/PageMore.vue'
import { useRouter } from 'vue-router';
 const router = useRouter();
const store = useStore();
const lang = computed(() => store.getters.lang);
const {
  i18n: { t }, // 解构实例用具t 访问语言变量
} = useLocale()
function gotoChange(e) {
    if (e.target.getAttribute('class')!=='moreBox') router.push('/funPages/Baseline&Change')
}

</script>

<style scoped lang="scss">
.img{
  transform: translate(-12%, 27%);
  scale: 1.65 !important;

  &__en{
    transform: translate(-12%, 23%);
    scale: 1.45 !important;
  }
}
.pageBtn{
  width: 105%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pMore{
  z-index: 999;
}
</style>

