
import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from '@/i18n/index'
import PageTemplate from "@/pages/Pc/PageTemplate.vue";
import SideHeader from "@/components/Pc/SideHeader.vue";
import TurntableCard from "@/components/Pc/TurntableCard.vue";

//styles
import "./styles/main.scss";

//element-plus
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'


import MetaInfo from 'vue-meta-info';

const app = createApp(App);
app.component("page-template", PageTemplate);
app.component("side-header", SideHeader);
app.component('turntable-card', TurntableCard);
app.use(store).use(router).use(ElementPlus).use(i18n).use(MetaInfo).mount('#app')
