<template>
    <page-template id="xuqiu" style="padding-top: 8rem; height: 100vh;">
        <template #header>
            <side-header>
                <template #heading-primary>MappingSpace</template>
                <template #heading-secondary>{{ t('funSafety.fta.info') }}</template>
                <!-- <template #heading-secondary2>{{ t('homepage.requirement.title') }}</template> -->
                <template #heading-secondary2>{{ t('funSafety.fta.title') }}</template>
                <template #description>
                    <ul>
                        <li><p>{{ t('funSafety.fta.desc1') }}</p></li>
                        <li><p>{{ t('funSafety.fta.desc2') }}</p></li>
                        <li><p>{{ t('funSafety.fta.desc3') }}</p></li>
                        <li><p>{{ t('funSafety.fta.desc4') }}</p></li>
                    </ul>
                </template>
            </side-header>
        </template>
        <template #default>
            <img :src="require(`@/assets/imgs/FTA_${lang}.webp`)" alt="" style="scale: 1;height: 30rem;width: 50rem;" class="FTA-img"
                :class="{ 's__en': lang === 'en' }">
        </template>
    </page-template>
</template>

<script setup lang="ts">
import useLocale from '@/hooks/useLocale' // 国际化hook
import { computed } from '@vue/reactivity';
import { useStore } from "vuex";
const store = useStore();
const lang = computed(() => store.getters.lang);
const {
    i18n: { t }, // 解构实例用具t 访问语言变量
} = useLocale()
</script>

<style scoped>
.FTA-img {
    scale: 1.3 !important;
    transform: translate(-20%, 40%);
    z-index: -100;

    &__en {
        transform: translate(-19%, 26%);
    }
}
</style>