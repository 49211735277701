<template>
  <div>
    <!-- <home-page></home-page> -->
    <hara-page></hara-page>
    <dfmea-page></dfmea-page>
    <FTAPage></FTAPage>
  </div>
</template>

<script setup lang="ts">
import HomePage from "@/pages/Mobile/MbHomePage.vue";
import HaraPage from "@/pages/Pc/HaraPage.vue";
import DfmeaPage from "@/pages/Pc/DFMEAPage.vue";
import FTAPage from "@/pages/Pc/FTAPage.vue";
</script>
<script lang="ts">
export default {
  metaInfo: {
    meta: [
      {
        name: 'keywords',
        content: 'HARA、DFMEA、FTA、HAZOP、思维导图、智能计算ASIL安全等级、数据报表、ISO26262、功能安全'
      },
      {
        name: 'keywords',
        content: 'HARA、DFMEA、FTA、HAZOP、Mind mapping、Intelligent calculation of ASIL safety levels、Data report、ISO26262、Functional safety'
      },
      {
        name: 'description',
        content: 'MappingSpace可以提供危害分析及风险评估，实时跟踪改进措施，故障树分析等等。',
      },
      {
        name: 'description',
        content: 'MappingSpace can provide Hazard Analysis and Risk Assessment,real-time tracking of improvement measures, fault tree analysis,etc.',
      },
    ],
  }
}
</script>
