<template>
  <page-template :right-header="true" has-background id="jixian">
    <template #header>
      <side-header class="right-header">
        <template #heading-primary>MappingSpace</template>
        <template #heading-secondary>
          <div  class="pageBtn">
            {{t('homepage.baseline.info')}}
            <PageMore @click="gotoBL" class="pMore" :message="'moreBox'"></PageMore>
          </div>
        </template>
        <template #heading-secondary2>{{t('homepage.baseline.title')}}</template>
        <template #description>{{t('homepage.baseline.desc')}}
          <div class="replace right">
            <div class="title" style="margin-left: 26rem;">{{t("homepage.replace")}}</div>
            <div class="li1">
              <div class="li11">Polarion</div>
              <div class="li11">IBM Doors</div>
              <div class="li11" style="width: 10rem;">SVN</div>
            </div>
            <div class="li2">
              <div class="li22">{{ t("homepage.baseline.li1") }}</div>
              <div class="li22">{{ t("homepage.baseline.li1") }}</div>
              <div class="li22" style="width: 10rem;">{{ t("homepage.baseline.li2") }}</div>
            </div>
          </div>
        </template>
      </side-header>
    </template>
    <template #default>
      <img src="../../assets/imgs/jixianmain.webp" alt="" class="img">
    </template>
  </page-template>
</template>

<script setup lang="ts">
import useLocale from '@/hooks/useLocale' // 国际化hook
import { computed } from '@vue/reactivity';
import { useStore} from "vuex";
import PageMore from '@/pages/Pc/PageMore.vue'
import { useRouter } from 'vue-router';
const router = useRouter();
const store = useStore();
const lang = computed(() => store.getters.lang);
const {
  i18n: { t }, // 解构实例用具t 访问语言变量
} = useLocale()
function gotoBL(e) {
    if (e.target.getAttribute('class')!=='moreBox') router.push('/funPages/Baseline&Change')
}

</script>

<style scoped>
.img{
  scale: 2.4!important;
  transform: translate(-24%, 40%);
}
.pageBtn{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pMore{
  z-index: 999;
}
</style>

