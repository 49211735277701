import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
//Pc组件
import TestV from "@/TestV.vue";
import HomeView from "@/views/Pc/HomeView.vue";
import AboutView from "@/views/Pc/AboutView.vue";
import PriceView from "@/views/Pc/PriceView.vue";
import funcSafety from "@/views/Pc/FunSafetyView.vue"
import customer_case from "@/views/Pc/CustomerCaseView.vue"
import automotive_toolchain from "@/views/Pc/AutomotiveToolchainView.vue"
import information_security from "@/views/Pc/InformationSecurityView.vue"
import industrial_intelligent_manufactur from "@/views/Pc/IndustrialIntelligentManufacturView.vue"
import funPages from '@/views/Pc/funPages.vue';
//移动端组件
import MbHomeView from "@/views/Mobile/MbHomeView.vue";
import MbPriceView from "@/views/Mobile/MbPriceView.vue";
import MbAboutView from "@/views/Mobile/MbAboutView.vue";
const routes: Array<RouteRecordRaw> = [
  //Pc路由
  {
    path: "/test",
    component: TestV
  },
  {
    path: "/home",
    component: HomeView
  },
  {
    path: "/about-us",
    component: AboutView
  },
  {
    path: "/",
    redirect: "/home"
  },
  {
    path: "/price",
    component: PriceView
  },
  {
    path: '/funcSafety',
    component: funcSafety,
  },
  {
    path: '/customer_case/:pageId',
    component: customer_case,

  },
  {
    path: '/automotive_toolchain',
    component: automotive_toolchain,
  },
  {
    path: '/information_security',
    component: information_security,
  },
  {
    path: '/industrial_intelligent_manufactur',
    component: industrial_intelligent_manufactur,
  },
  {
    path: '/funPages/:pageId',
    component: funPages,
    
  },
  
//移动端路由
  {
    path: "/mb_home",
    component: MbHomeView
  },
  {
    path: "/mb_price",
    component: MbPriceView
  },
  {
    path: "/mb_about",
    component: MbAboutView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(function (to, from, next) {
  const { pageId } = to.params;
  window.scroll(0, 0)
  if (to.path.startsWith('/customer_case') && (pageId === undefined || pageId === null)) {
    next('/');
  } else {
    next();
  }
})

export default router
