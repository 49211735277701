<template>
  <section
    class="page-template"
    :class="{ 'u-bg-primary': props.hasBackground }"
  >
    <div class="page-template__container">
      <div class="page-header" :class="{ 'u-right-header': props.rightHeader }">
        <slot name="header"></slot>
      </div>
      <div class="page-main-img" :class="{ 'left-content': props.rightHeader }">
        <slot></slot>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { defineProps } from "vue";

const props = defineProps({
  hasBackground: {
    type: Boolean,
    required: false,
    default: false,
  },
  rightHeader: {
    type: Boolean,
    required: false,
    default: false,
  },
});
</script>

<style scoped>
.left-content {
  grid-column: 1;
  grid-row: 1;
  margin-left: 0;
}
</style>
