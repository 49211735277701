<template>
  <page-template :right-header="true" has-background id="jixian" style="height: 100vh;padding-top: 8rem;">
    <template #header>
      <side-header class="right-header">
        <template #heading-primary>MappingSpace</template>
        <template #heading-secondary>{{ t('funSafety.dfmea.info') }}</template>
        <template #heading-secondary2>{{ t('funSafety.dfmea.title') }}</template>
        <template #description>
          <ul>
            <li><p>{{ t('funSafety.dfmea.desc1') }}</p></li>
            <li><p>{{ t('funSafety.dfmea.desc2') }}</p></li>
            <li><p>{{ t('funSafety.dfmea.desc3') }}</p></li>
            <li><p>{{ t('funSafety.dfmea.desc4') }}</p></li>
          </ul>
        </template>
      </side-header>
    </template>
    <template #default>
      <img :src="require(`@/assets/imgs/DFMEA_${lang}.webp`)" alt="" style="scale: 1;height: 30rem;width: 60rem;" class="DFMEA-img"
        :class="{ 's__en': lang === 'en' }">
    </template>
  </page-template>
</template>
  
<script setup lang="ts">
import useLocale from '@/hooks/useLocale' // 国际化hook
import { computed } from '@vue/reactivity';
import { useStore } from "vuex";

const store = useStore();
const lang = computed(() => store.getters.lang);
const {
  i18n: { t }, // 解构实例用具t 访问语言变量
} = useLocale()
</script>
  
<style scoped>
.DFMEA-img {
  scale: 1.3 !important;
  transform: translate(-35%, 40%);
  z-index: -100;

  &__en {
    transform: translate(-19%, 26%);
  }
}
</style>
  
