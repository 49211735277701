<template>
  <div class="topall" :style="{ height: showNavPopup ? '70rem' : '0' }">
    <div class="listall">
      <div class="top" @click="navToHome">{{ t("topBar.homepage") }}</div>
      <span @click="scrollView('demand')">{{
        t("homepage.cooperation.requirement")
      }}</span>
      <span @click="scrollView('work')">{{
        t("homepage.cooperation.workItem")
      }}</span>
      <span @click="scrollView('test')">{{
        t("homepage.cooperation.test")
      }}</span>
      <span @click="scrollView('base')">{{
        t("homepage.cooperation.baseline")
      }}</span>
      <span @click="scrollView('change')">{{
        t("homepage.cooperation.change")
      }}</span>
      <span @click="scrollView('release')">{{
        t("homepage.cooperation.publish")
      }}</span>
      <span @click="scrollView('online')">{{
        t("homepage.cooperation.onlineJudge")
      }}</span>
      <span @click="scrollView('docu')">{{
        t("homepage.cooperation.document")
      }}</span>
      <span @click="scrollView('agile')">{{
        t("homepage.cooperation.agile")
      }}</span>
      <div class="top" @click="navToPrice">{{ t("topBar.price") }}</div>
      <span @click="navToAbout" class="about">{{ t("topBar.aboutUs") }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, defineProps, defineEmits } from "vue";
import { useRouter, useRoute } from "vue-router";
import useLocale from "@/hooks/useLocale"; // 国际化hook
const {
  i18n: { t }, // 解构实例用具t 访问语言变量
  changeLocale, // 修改国际化
} = useLocale();
let $emit = defineEmits(["custom-event"]);
const router = useRouter();
const route = useRoute();
const props = defineProps({
  showNavPopup: Boolean,
});
function navToPrice() {
  $emit("custom-event", false);
  router.push("/mb_price");
}

function navToAbout() {
  $emit("custom-event", false);
  router.push("/mb_about");
}
function navToHome() {
  $emit("custom-event", false);
  router.push("/mb_home");
}
function scrollView(id: string) {
  if (route.path == "/mb_home") {
    document.querySelector(`#${id}`)!.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
    $emit("custom-event", false);
  }
}
</script>

