<template>
    <div class="customer-page2">
        <div class="customer-page2-top">
            <div class="title">{{ t(`${props.message.title}`)}}</div>
            <div class="content" :class="{'content-en': lang === 'en'}">
                <div class="content1">
                    {{ t(`${props.message.content1}`) }}
                </div>
                <div class="content2">
                    {{ t(`${props.message.content2}`) }}
                </div>
                <div class="content3">
                    {{ t(`${props.message.content3}`) }}
                </div>
            </div>
        </div>
        <div class="customer-page2-bottom" :class="{'customer-page2-bottom-en': lang === 'en'}">
            <div class="Challenge-card">
                <div class="Challenge-card-top">
                    <div class="title1">{{ t("client.customerPage2.card1.title") }}</div>
                    <div class="content1">
                        {{ t(`${props.message.card1content1}`) }}
                    </div>
                </div>
                <div class="Challenge-card-bottom">
                    <div class="content2">{{ t(`${props.message.card1content2}`) }}</div>
                    <div class="box" @click="handleItemClick('box1')">{{t("client.customerPage2.learn")}}</div>
                </div>
            </div>
            <div class="Challenge-card">
                <div class="Challenge-card-top">
                    <div class="title1">{{ t("client.customerPage2.card2.title") }}</div>
                    <div class="content1">
                        {{ t(`${props.message.card2content1}`) }}
                    </div>
                </div>
                <div class="Challenge-card-bottom">
                    <div class="content2">{{ t(`${props.message.card2content2}`) }}</div>
                    <div class="box" @click="handleItemClick('box2')">{{t("client.customerPage2.learn")}}</div>
                </div>
            </div>
            <div class="Challenge-card">
                <div class="Challenge-card-top">
                    <div class="title1">{{ t("client.customerPage2.card3.title") }}</div>
                    <div class="content1">
                        {{ t(`${props.message.card3content1}`) }}
                    </div>
                </div>
                <div class="Challenge-card-bottom">
                    <div class="content2">{{ t(`${props.message.card3content2}`) }}</div>
                    <div class="box" @click="handleItemClick('box3')">{{t("client.customerPage2.learn")}}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import useLocale from '@/hooks/useLocale' // 国际化hook
import { computed } from '@vue/reactivity';
import { useStore} from "vuex";

import { ref,defineProps,nextTick } from 'vue'

const store = useStore();
const lang = computed(() => store.getters.lang);
const {
  i18n: { t }, // 解构实例用具t 访问语言变量
} = useLocale()

// 接受父组件传递的信息
const props = defineProps(['message']);


const handleItemClick = (item: string) => {
  nextTick(()=>{
    document.querySelector(`#${item}`)!.scrollIntoView({
    behavior: "smooth"
  })
  })
}
</script>
