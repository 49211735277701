<template>
  <page-template :right-header="true" id="pingshen">
    <template #header>
      <side-header>
        <template #heading-primary>MappingSpace</template>
        <template #heading-secondary>{{t('homepage.onlineJudge.info')}}</template>
        <template #heading-secondary2>{{t('homepage.onlineJudge.title')}}</template>
        <template #description>{{t('homepage.onlineJudge.desc')}}
          <div class="replace right">
            <div class="title" :class="{'title-en': lang === 'en'}">{{t("homepage.replace")}}</div>
            <div class="li1">
              <div class="li11">Word</div>
              <div class="li11">{{ t("homepage.onlineJudge.li1") }}</div>
              <div class="li11" :class="{'li11-en': lang === 'en'}">{{ t("homepage.onlineJudge.li2") }}</div>
            </div>
            <div class="li2">
              <div class="li22">~0</div>
              <div class="li22">~0</div>
              <div class="li22" :class="{'li22-en': lang === 'en'}">{{ t("homepage.onlineJudge.li3") }}</div>
            </div>
          </div>
        </template>
      </side-header>
    </template>
    <template #default>
      <img style="margin-top: 5rem;" :src="require(`@/assets/imgs/pingshenmain_${lang}.webp`)" alt="" class="img" :class="{'img__en': lang==='en'}">
    </template>
  </page-template>
</template>

<script setup lang="ts">
import useLocale from '@/hooks/useLocale' // 国际化hook
import { computed } from '@vue/reactivity';
import { useStore} from "vuex";

const store = useStore();
const lang = computed(() => store.getters.lang);
const {
  i18n: { t }, // 解构实例用具t 访问语言变量
} = useLocale()
</script>

<style scoped lang="scss">
.img{
  scale: 1.8!important;
  transform: translate(-5%, 11%);

  &__en{
    scale: .8!important;
    transform: translate(-30%, -2%);
  }
  
}
</style>
