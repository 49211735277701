<template>
  <div class="test"></div>
</template>

<script setup lang="ts">
</script>

<style scoped lang="scss">
.test{
  padding: 20rem;
  background: repeating-radial-gradient(50% 30%,red 0,red 10%,yellow 10%,yellow 20%);
}
</style>
