<template>
  <section class="the-mbnav">
    <div class="logo-box">
      <img src="../../assets/imgs/logo.png" alt="logo" />
    </div>
    <div class="el-dropdownlist">
      <img @click="showTop" src="../../assets/imgs/menu.png" alt="" />
    </div>
    <el-dropdown class="el-dropdownlan">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="lang-icon"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="#2c3e50"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <circle cx="12" cy="12" r="9" />
        <line x1="3.6" y1="9" x2="20.4" y2="9" />
        <line x1="3.6" y1="15" x2="20.4" y2="15" />
        <path d="M11.5 3a17 17 0 0 0 0 18" />
        <path d="M12.5 3a17 17 0 0 1 0 18" />
      </svg>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item @click="switchLang('zh')"
            >简体中文</el-dropdown-item
          >
          <el-dropdown-item @click="switchLang('en')">English</el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </section>
  <NavPopup :showNavPopup="drawer" @custom-event="handleMessage"></NavPopup>
</template>

<script setup lang="ts">
import useLocale from "@/hooks/useLocale"; // 国际化hook
import NavPopup from "@/components/Mobile/NavPopup.vue";
import { ref } from "vue";
const {
  i18n: { t }, // 解构实例用具t 访问语言变量
  changeLocale, // 修改国际化
} = useLocale();

// 切换语言
const drawer = ref(false);
function switchLang(lang: string) {
  changeLocale(lang);
  localStorage.setItem("lang", lang);
}
function handleMessage(e:boolean){
  drawer.value=e
}
function showTop() {
  drawer.value = !drawer.value;
}
</script>

<style scoped lang="scss">
a:hover,
a:active {
  font-weight: 700;
}
.lang-icon {
  width: 3.4rem;
  height: 3.4rem;
  stroke: #fff;
  cursor: pointer;
}

</style>
