<template>
  <div class="card">
    <div class="card__header">
      <slot name="header"></slot>
    </div>
    <div class="card__main">
      <slot name="main"></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
</script>

