<template>
    <section>
        <!-- 客户介绍 -->
        <div class="case">
            <div class="titleBoxCase">
                <p @click="showATJ" :class="{ titleLine: isShowATJ }">{{t('homepage.case.Aotecar')}}</p>
                <p @click="showTY" :class="{ titleLine: isShowTY }">{{t('homepage.case.Tongyu')}}</p>
                <p @click="showFER" :class="{ titleLine: isShowFER }">{{t('homepage.case.Fuerda')}}</p>
            </div>
            <div class="imgBox">
                <div class="relativeBox"  @click="gotoCase">
                    <img :src="caseUrl">
                    <div class="atj_word">
                       <div class="lineBox">
                            <div class="line1"></div>
                            <div class="line2"></div>
                       </div>
                        <!-- <el-tooltip
                                class="box-item"
                                effect="dark"
                                placement="top"
                                :content="t(`homepage.case.${caseTitle}`)"
                            > -->
                            <p class="p1">{{t(`homepage.case.${caseTitle}`)}}</p>
                        <!-- </el-tooltip> -->
                       <p v-if="isShowTip" class="p2">{{t(`homepage.case.${caseText}`)}}</p>
                       <div class="caseBtn">
                            <p>{{t('homepage.case.caseRead')}}</p>
                       </div>
                    </div>
                </div>
                
            </div>

        </div>
        <div id="More"></div>
        <!-- 更多资源 -->
        <div  class="resource">
            <!-- 标题 -->
            <div class="titleBoxResource">
                <p>{{t('homepage.case.moreResources')}}</p>
                
            </div>
            <!-- 卡片盒子 -->
            <div class="cardBox">
                <div class="leftBtn" @click="leftShift">
                    <img src="../../assets/imgs/leftBtn.png">
                </div>
                <!-- 卡片 -->
                <div class="cardvessel" @click="gotoPageDown">
                    <div v-for="(val,index) in resourceArr" :key="index" class="cardRe">
                        <div class="cardImgTop">
                            <img :src="val.imgurl" >
                        </div>
                        <div class="cardWord">
                            <p>{{ t(`homepage.case.${val.title}`) }}</p>

                                <p>{{ t(`homepage.case.${val.text}`) }}</p>


                            
                        </div>
                    </div>

                </div>
                <div class="rightBtn" @click="rightShift">
                    <img src="../../assets/imgs/rightBtn.png">
                </div>
            </div>
        </div>
    </section>

</template>

<script setup lang="ts">
import useLocale from '@/hooks/useLocale' // 国际化hook
import { computed } from '@vue/reactivity';
import { useStore } from "vuex";
import { ref } from 'vue';
import { useRouter } from 'vue-router';
const store = useStore();
const lang = computed(() => store.getters.lang);
const {
  i18n: { t }, // 解构实例用具t 访问语言变量
} = useLocale()
 const router = useRouter();
const isShowATJ = ref(true)
const isShowTY = ref(false)
const isShowFER = ref(false)

const caseArr = ref(
    [
        {
            'imgurl': require("../../assets/imgs/aotejia_resource.png"),
            'title': 'caseTitle1', "text": 'caseTitle1Text'
        },
        {
            'imgurl': require("../../assets/imgs/tongyu_resource.png"),
            'title': 'caseTitle2', "text": 'caseTitle2Text'
        },
        {
            'imgurl': require("../../assets/imgs/fuerda_resource.png"),
            'title': 'caseTitle3', "text":'caseTitle3Text'
        }
    
]
)

const caseUrl = computed(() => {
    if (isShowATJ.value) return caseArr.value[0].imgurl;
    if (isShowTY.value) return caseArr.value[1].imgurl;
    if (isShowFER.value) return caseArr.value[2].imgurl;
});

const caseTitle = computed(() => {
    if (isShowATJ.value) return caseArr.value[0].title;
    if (isShowTY.value) return caseArr.value[1].title;
    if (isShowFER.value) return caseArr.value[2].title;
});

const caseText = computed(() => {
    if (isShowATJ.value) return caseArr.value[0].text;
    if (isShowTY.value) return caseArr.value[1].text;
    if (isShowFER.value) return caseArr.value[2].text;
});


const resourceArr = ref(
    [
        {
            'imgurl': require("../../assets/imgs/car_resource.png"),
            'title': 'resourceTitle1', "text": 'resourceTitle1Text'
        },
        {
            'imgurl': require("../../assets/imgs/industry_resource.png"),
            'title': 'resourceTitle2', "text": 'resourceTitle2Text'
        },
        {
            'imgurl': require("../../assets/imgs/plane_resource.png"),
            'title': 'resourceTitle3', "text":'resourceTitle3Text'
        },
        {
            'imgurl': require("../../assets/imgs/plane1_resource.png"),
            'title': 'resourceTitle4', "text": 'resourceTitle4Text'
        },
    
]
)





function showATJ() {
    isShowATJ.value = true
    isShowTY.value = false
    isShowFER.value=false
}
function showTY() {
    isShowATJ.value = false
    isShowTY.value = true
    isShowFER.value=false
}
function showFER() {
    isShowATJ.value = false
    isShowTY.value = false
    isShowFER.value=true
}


function leftShift() {
    resourceArr.value=resourceArr.value.slice(1).concat(resourceArr.value.slice(0, 1));
}
function rightShift() {
    resourceArr.value=resourceArr.value.slice(-1).concat(resourceArr.value.slice(0, -1));
}


function gotoCase() {
    if(isShowATJ.value) router.push('/customer_case/aotecar')
    else if(isShowTY.value)  router.push('/customer_case/TongyuAutomobile')
    else if(isShowFER.value)  router.push('/customer_case/Fuerda')
    
}


function gotoPageDown(e: { target: { parentNode: { parentNode: any; }; }; }) {
    let tag=e.target.parentNode.parentNode;
    if (tag.getAttribute('class') === 'cardvessel') tag = e.target.parentNode;
    let title = tag.querySelectorAll('p')[0].innerHTML
    if (title === '汽车工具链' || title === 'Automotive toolchain') {
        router.push('/automotive_toolchain')
    } else if (title === '工业智造' || title === 'Intelligent Manufacturing') {
         router.push('/industrial_intelligent_manufactur')
    }
    else if (title === '下载发展白皮书' || title === 'Download white paper') {
        const fileUrl="https://ytdevops-automind.oss-cn-shanghai.aliyuncs.com/Frontend/website/%E3%80%90AUTOSEMO%E3%80%91%E4%B8%AD%E5%9B%BD%E6%B1%BD%E8%BD%A6%E5%9F%BA%E7%A1%80%E8%BD%AF%E4%BB%B6%E5%8F%91%E5%B1%95%E7%99%BD%E7%9A%AE%E4%B9%A63%E3%80%90%E5%8F%91%E7%8E%B0%E6%8A%A5%E5%91%8A%20fxbaogao.com%E3%80%91.pdf";
        const fileName = lang.value === 'zh' ? 'AutoSemo中国汽车基础软件发展白皮书 3.0.pdf' : 'China Automotive Basic Software Development White Paper 3.0.pdf';
        downloadPDF(fileUrl,fileName);
    }
    else if (title === '下载软件测试报告' || title === 'Download testing report') {
         const fileUrl="https://ytdevops-automind.oss-cn-shanghai.aliyuncs.com/Frontend/website/%E5%9F%BA%E7%A1%80%E8%BD%AF%E4%BB%B6%E6%B5%8B%E8%AF%95%E7%A0%94%E7%A9%B6%E6%8A%A5%E5%91%8AV1.18.pdf"
        const fileName = lang.value === 'zh' ? 'AutoSemo中国汽车基础软件测试研究报告 1.0.pdf' : 'China Automotive Basic Software Testing Research Report 1.0.pdf';
        downloadPDF(fileUrl,fileName);
    }
    function downloadPDF(fileUrl: string,fileName: string) {
            const url = fileUrl
            fetch(url)
                .then(response => response.blob())
                .then(blob => {
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = fileName;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(link.href);
                })
                .catch(error => console.error('Download error:', error));
        }

}


const isShowTip = computed(() => {
    return lang.value==='zh'
})


</script>

<style scoped lang="scss">
.content {
  max-width: 200px; // 设置一个最大宽度
  white-space: pre-wrap; // 超出这个宽度就直接换行
}
</style>
