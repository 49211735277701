<template>
    <toolchain-page1></toolchain-page1>
    <toolchain-page2></toolchain-page2>
</template>
<script setup lang="ts">
import ToolchainPage1 from '@/pages/Pc/ToolchainPage1.vue'
import ToolchainPage2 from '@/pages/Pc/ToolchainPage2.vue'
</script>
<script lang="ts">
export default {
  metaInfo: {
    meta: [
      {
        name: 'keywords',
        content: 'V模型、研发管理工具链、系统需求分析、系统架构设计、软件需求分析、软件架构设计、软件详细设计、编码、软件单元测试、软件集成测试、软件合格性测试、系统集成测试、系统合格性测试'
      },
      {
        name: 'keywords',
        content: 'V-model、R&D management toolchain、System requirement、System requirement、System qualification test、System architecture、System integration test、SW requirement、SW qualification test、SW architecture、SW integration test、SW detailed design、SW unit verification、Coding'
      },
      {
        name: 'description',
        content: 'MappingSpace是一款专门为汽车行业打造的研发管理软件。而汽车行业涉及的工具众多，因此我们也积极与一些高效工具进行打通，为您带来更全面、更懂汽车的研发管理工具链。',
      },
      {
        name: 'description',
        content: 'MappingSpace is a research and development management software specifically designed for the automotive industry.The automotive industry involves a multitude of tools,so we are proactively integrating with various efficient tools to provide you with a more comprehensive and automotive-savvy R&D management toolchain.',
      },
    ],
  }
}
</script>