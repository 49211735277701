<template>
    <div class="toolchain-page2">
        <div class="top" :class="{'top-en': lang === 'en'}">
            <div class="title">{{ t("toolchain.toolchainPage2.title") }}</div>
            <div class="content">{{ t("toolchain.toolchainPage2.content") }}</div>
        </div>
        <div class="bottom" :class="{'bottom-en': lang === 'en'}">
            <div class="lineImg">
                <!-- <img src="../../assets/imgs/leftline.png" alt="" class="leftline">
                <img src="../../assets/imgs/rightline.png" alt="" class="rightline"> -->
                <div class="boxs">
                    <div class="box">
                        <div class="systemRequirement box11">{{ t("toolchain.toolchainPage2.systemRequirement") }}</div>
                        <div class="systemQualificationTest box11" :class="{'selectedcard': selectedcard === 'systemQualificationTest'}" @click="ejectTurntable('systemQualificationTest')">
                            {{ t("toolchain.toolchainPage2.systemQualificationTest") }}
                            <div class="li" v-if="selectedcard === 'systemQualificationTest'">
                                <turntable-card :message="selectedcard" @selected="receive"></turntable-card>
                            </div>
                        </div>
                    </div>
                    <div class="box">
                        <div class="systemArchitecture box11">{{ t("toolchain.toolchainPage2.systemArchitecture") }}</div>
                        <div class="systemIntegrationTest box11" :class="{'selectedcard': selectedcard === 'systemIntegrationTest'}"  @click="ejectTurntable('systemIntegrationTest')">
                            {{ t("toolchain.toolchainPage2.systemIntegrationTest") }}
                            <div class="li" v-if="selectedcard === 'systemIntegrationTest'">
                                <turntable-card :message="selectedcard" @selected="receive"></turntable-card>
                            </div>
                        </div>
                    </div>
                    <div class="box">
                        <div class="SWRequirement box11">{{ t("toolchain.toolchainPage2.SWRequirement") }}</div>
                        <div class="SWQualificationTest box11" :class="{'selectedcard': selectedcard === 'SWQualificationTest'}"  @click="ejectTurntable('SWQualificationTest')">
                            {{ t("toolchain.toolchainPage2.SWQualificationTest") }}
                            <div class="li" v-if="selectedcard === 'SWQualificationTest'">
                                <turntable-card :message="selectedcard" @selected="receive"></turntable-card>
                            </div>
                        </div>
                    </div>
                    <div class="box">
                        <div class="SWArchitecture box11" :class="{'selectedcard': selectedcard === 'SWArchitecture'}" @click="ejectTurntable('SWArchitecture')">
                            {{ t("toolchain.toolchainPage2.SWArchitecture") }}
                            <div class="li" v-if="selectedcard === 'SWArchitecture'">
                                <turntable-card :message="selectedcard" @selected="receive"></turntable-card>
                            </div>
                        </div>
                        <div class="SWIntegrationTest box11" :class="{'selectedcard': selectedcard === 'SWIntegrationTest'}"  @click="ejectTurntable('SWIntegrationTest')">
                            {{ t("toolchain.toolchainPage2.SWIntegrationTest") }}
                            <div class="li" v-if="selectedcard === 'SWIntegrationTest'">
                                <turntable-card :message="selectedcard" @selected="receive"></turntable-card>
                            </div>
                        </div>
                    </div>
                    <div class="box">
                        <div class="SWDetailedDesign box11" :class="{'selectedcard': selectedcard === 'SWDetailedDesign'}"  @click="ejectTurntable('SWDetailedDesign')">
                            {{ t("toolchain.toolchainPage2.SWDetailedDesign") }}
                            <div class="li" v-if="selectedcard === 'SWDetailedDesign'">
                                <turntable-card :message="selectedcard" @selected="receive"></turntable-card>
                            </div>
                        </div>
                        <div class="SWUnitVerification box11" :class="{'selectedcard': selectedcard === 'SWUnitVerification'}"  @click="ejectTurntable('SWUnitVerification')">
                            {{ t("toolchain.toolchainPage2.SWUnitVerification") }}
                            <div class="li" v-if="selectedcard === 'SWUnitVerification'">
                                <turntable-card :message="selectedcard" @selected="receive"></turntable-card>
                            </div>
                        </div>
                    </div>
                    <div class="box">
                        <div class="coding box11" :class="{'selectedcard': selectedcard === 'coding'}"  @click="ejectTurntable('coding')">
                            {{ t("toolchain.toolchainPage2.coding") }}
                            <div class="li" v-if="selectedcard === 'coding'">
                                <turntable-card :message="selectedcard" @selected="receive"></turntable-card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="friend">
            <eolink-page v-show="selected === 'eolink'"></eolink-page>
            <feysh-technology-page v-show="selected === 'feyshTechnology'"></feysh-technology-page>
            <gitlab-page v-show="selected === 'gitlab'"></gitlab-page>
        </div>
    </div>
</template>
<script setup lang="ts">
import EolinkPage from './EolinkPage.vue';
import GitlabPage from './GitlabPage.vue';
import FeyshTechnologyPage from './FeyshTechnologyPage.vue';
import useLocale from '@/hooks/useLocale' // 国际化hook
import { computed } from '@vue/reactivity';
import { useStore} from "vuex";
import {ref} from 'vue';
import TurntableCard from '@/components/Pc/TurntableCard.vue';

const store = useStore();
const lang = computed(() => store.getters.lang);
const {
  i18n: { t }, // 解构实例用具t 访问语言变量
} = useLocale()

// 判断是哪个页面显示  第三页
const selected = ref('eolink');

// 选择的是哪个
const selectedcard = ref('SWArchitecture');

// 弹出转盘
const ejectTurntable = (str :string) => {
    selectedcard.value = str;
}

// 接受转盘子组件传递的值
const receive = (str: string) => {
    selected.value = str;
}

</script>