<template>
  <div class="u-divider" style="background-color: rgba(0, 128, 255, 0.01);height: 1rem"></div>

  <section class="cooperation-page u-bg-primary">
    <div class="middle" :class="{'middle-en': lang === 'en'}">
        <div class="h1" v-html="$t('homepage.cooperation.secondTitle')"></div>
        <PageMore @click="gotoAI" :message="'moreBoxWhite'" class="pMore"></PageMore>
        <div class="h2" v-html="$t('homepage.cooperation.description')"></div>
    </div>

     <div class="text-content" :class="{'text-content-en': lang === 'en'}">
        <div class="text">
          <div class="text_title">{{ t('homepage.cooperation.oneAI.title') }}</div>
          <div class="text_content">{{ t('homepage.cooperation.oneAI.description') }}</div>
        </div>
        <div class="line"></div>
        <div class="text text2">
          <div class="text_title">{{ t('homepage.cooperation.twoAI.title') }}</div>
          <div class="text_content">{{ t('homepage.cooperation.twoAI.description') }}</div>
        </div>
        <div class="line"></div>
        <div class="text text3">
          <div class="text_title">{{ t('homepage.cooperation.threeAI.title') }}</div>
          <div class="text_content">{{ t('homepage.cooperation.threeAI.description') }}</div>
        </div>
     </div>
    
    <div class="bottom">
      <!-- <img src="../../assets/imgs/cooperation-aihand.png" class="ai-hand"> -->
      <div class="title">
      <img src="../../assets/imgs/配置管理.png" style="height: 2.9rem;width: 2.9rem;margin-right: 2.4rem">
      <!--     <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 512 512"><title>Share Social</title><circle cx="128" cy="256" r="48" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><circle cx="384" cy="112" r="48" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><circle cx="384" cy="400" r="48" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M169.83 279.53l172.34 96.94M342.17 135.53l-172.34 96.94"/></svg>-->
      <h2 :class="{'spacing__en': lang==='en'}">{{ t('homepage.cooperation.title') }}</h2>
     </div>
     <div class="top-mid">
      <ul class="sub-menu">
        <li @click="scrollView('xuqiu')" id="xuqiu">
          <div class="sub-menu__item" :class="{ 'selected': selectedIndex === 'xuqiu' }">
            <p :class="{'letter__en': lang==='en'}">{{ t('homepage.cooperation.requirement') }}</p>
            <div class="img-box">
              <img src="../../assets/imgs/xuqiuicon.webp" alt="">
            </div>
          </div>
          <!-- <div class="bottom-icon">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-down" width="44"
                 height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round"
                 stroke-linejoin="round" v-show="selectedIndex !== 'xuqiu'">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <polyline points="6 9 12 15 18 9"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-up" width="44"
                 height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round"
                 stroke-linejoin="round" v-show="selectedIndex === 'xuqiu'">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <polyline points="6 15 12 9 18 15"/>
            </svg>
          </div> -->
        </li>
        <li @click="scrollView('gongzuo')" id="gongzuo">
          <div class="sub-menu__item" :class="{ 'selected': selectedIndex === 'gongzuo' }">
            <p :class="{'letter__en': lang==='en'}">{{ t('homepage.cooperation.workItem') }}</p>
            <div class="img-box">
              <img src="../../assets/imgs/gongzuoicon.webp" alt="">
            </div>
          </div>
          <!-- <div class="bottom-icon">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-down" width="44"
                 height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round"
                 stroke-linejoin="round" v-show="selectedIndex !== 'gongzuo'">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <polyline points="6 9 12 15 18 9"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-up" width="44"
                 height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round"
                 stroke-linejoin="round" v-show="selectedIndex === 'gongzuo'">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <polyline points="6 15 12 9 18 15"/>
            </svg>
          </div> -->
        </li>
        <li @click="scrollView('ceshi')" id="ceshi">
          <div class="sub-menu__item" :class="{ 'selected': selectedIndex === 'ceshi' }">
            <p :class="{'letter__en': lang==='en'}">{{ t('homepage.cooperation.test') }}</p>
            <div class="img-box" style="height: 6rem">
              <img style="height: 6rem;" src="../../assets/imgs/ceshiicon.webp" alt="">
            </div>
          </div>
          <!-- <div class="bottom-icon">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-down" width="44"
                 height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round"
                 stroke-linejoin="round" v-show="selectedIndex !== 'ceshi'">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <polyline points="6 9 12 15 18 9"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-up" width="44"
                 height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round"
                 stroke-linejoin="round" v-show="selectedIndex === 'ceshi'">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <polyline points="6 15 12 9 18 15"/>
            </svg>
          </div> -->
        </li>
        <li @click="scrollView('jixian')" id="jixian">
          <div class="sub-menu__item" :class="{ 'selected': selectedIndex === 'jixian' }">
            <p :class="{'letter__en': lang==='en'}">{{ t('homepage.cooperation.baseline') }}</p>
            <div class="img-box">
              <img src="../../assets/imgs/jixianicon.webp" alt="">
            </div>
          </div>
          <!-- <div class="bottom-icon">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-down" width="44"
                 height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round"
                 stroke-linejoin="round" v-show="selectedIndex !== 'jixian'">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <polyline points="6 9 12 15 18 9"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-up" width="44"
                 height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round"
                 stroke-linejoin="round" v-show="selectedIndex === 'jixian'">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <polyline points="6 15 12 9 18 15"/>
            </svg>
          </div> -->
        </li>
        <li @click="scrollView('biangeng')" id="biangeng">
          <div class="sub-menu__item" :class="{ 'selected': selectedIndex === 'biangeng' }">
            <p :class="{'letter__en': lang==='en'}">{{ t('homepage.cooperation.change') }}</p>
            <div class="img-box" style="height: 3.6rem; " :style="{marginTop:lang==='en'?'0.8rem':'1.6rem'}">
              <img style="height: 4.5rem;" src="../../assets/imgs/biangengicon.webp" alt="">
            </div>
          </div>
          <!-- <div class="bottom-icon">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-down" width="44"
                 height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round"
                 stroke-linejoin="round" v-show="selectedIndex !== 'biangeng'">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <polyline points="6 9 12 15 18 9"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-up" width="44"
                 height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round"
                 stroke-linejoin="round" v-show="selectedIndex === 'biangeng'">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <polyline points="6 15 12 9 18 15"/>
            </svg>
          </div> -->
        </li>
        <li @click="scrollView('fabu')" id="fabu">
          <div class="sub-menu__item" :class="{ 'selected': selectedIndex === 'fabu' }">
            <p :class="{'letter__en': lang==='en'}">{{ t('homepage.cooperation.publish') }}</p>
            <div class="img-box">
              <img src="../../assets/imgs/fabuicon.webp" alt="">
            </div>
          </div>
          <!-- <div class="bottom-icon">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-down" width="44"
                 height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round"
                 stroke-linejoin="round" v-show="selectedIndex !== 'fabu'">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <polyline points="6 9 12 15 18 9"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-up" width="44"
                 height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round"
                 stroke-linejoin="round" v-show="selectedIndex === 'fabu'">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <polyline points="6 15 12 9 18 15"/>
            </svg>
          </div> -->
        </li>
        <li @click="scrollView('pingshen')" id="pingshen">
          <div class="sub-menu__item" :class="{ 'selected': selectedIndex === 'pingshen' }">
            <p :class="{'letter__en': lang==='en'}" v-html="t('homepage.cooperation.onlineJudge')"></p>
            <div class="img-box" :class="{'img-box-en': lang === 'en'}">
              <img src="../../assets/imgs/pingshenicon.webp" alt="">
            </div>
          </div>
          <!-- <div class="bottom-icon">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-down" width="44"
                 height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round"
                 stroke-linejoin="round" v-show="selectedIndex !== 'pingshen'">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <polyline points="6 9 12 15 18 9"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-up" width="44"
                 height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round"
                 stroke-linejoin="round" v-show="selectedIndex === 'pingshen'">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <polyline points="6 15 12 9 18 15"/>
            </svg>
          </div> -->
        </li>
        <li @click="scrollView('wendang')" id="wendang">
          <div class="sub-menu__item" :class="{ 'selected': selectedIndex === 'wendang' }">
            <p :class="{'letter__en': lang==='en'}">{{ t('homepage.cooperation.document') }}</p>
            <div class="img-box">
              <img src="../../assets/imgs/wendangicon.webp" alt="">
            </div>
          </div>
          <!-- <div class="bottom-icon">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-down" width="44"
                 height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round"
                 stroke-linejoin="round" v-show="selectedIndex !== 'wendang'">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <polyline points="6 9 12 15 18 9"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-up" width="44"
                 height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round"
                 stroke-linejoin="round" v-show="selectedIndex === 'wendang'">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <polyline points="6 15 12 9 18 15"/>
            </svg>
          </div> -->
        </li>
        <li @click="scrollView('minjie')" id="minjie">
          <div class="sub-menu__item" :class="{ 'selected': selectedIndex === 'minjie' }">
            <p :class="{'letter__en': lang==='en'}" v-html="t('homepage.cooperation.agile')"></p>
            <!-- <p :class="{'letter__en': lang==='en'}">{{ t('homepage.cooperation.agile.info') }}</p> -->
            <div class="img-box">
              <img src="../../assets/imgs/minjieicon.webp" alt="">
            </div>
          </div>
          <!-- <div class="bottom-icon">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-down" width="44"
                 height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round"
                 stroke-linejoin="round" v-show="selectedIndex !== 'minjie'">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <polyline points="6 9 12 15 18 9"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-up" width="44"
                 height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round"
                 stroke-linejoin="round" v-show="selectedIndex === 'minjie'">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <polyline points="6 15 12 9 18 15"/>
            </svg>
          </div> -->
        </li>
      </ul>
     
    </div>
      
    </div>

    <!--   <div class="drawer">-->
    <!--     <el-carousel :interval="400000" type="card"  ref="items" height="38rem">-->
    <!--       <el-carousel-item v-for="item in 9" :key="item">-->
    <!--         <div class="drawer__item">-->
    <!--           <div class="img-box" >-->
    <!--             <img :src="require(`@/assets/imgs/drawer${item}_${lang}.webp`)" alt="">-->
    <!--           </div>-->
    <!--         </div>-->
    <!--       </el-carousel-item>-->
    <!--     </el-carousel>-->
    <!--   </div>-->
  </section>
</template>

<script setup lang="ts">
import {ref, reactive} from "vue";

import useLocale from "@/hooks/useLocale"; // 国际化hook
import {computed} from "@vue/reactivity";
import {useStore} from "vuex";
import { defineEmits } from "vue";
import PageMore from '@/pages/Pc/PageMore.vue'
import { useRouter } from 'vue-router';
 const router = useRouter();
const items = ref();
const store = useStore();
const lang = computed(() => store.getters.lang);
const {
  i18n: {t} // 解构实例用具t 访问语言变量
} = useLocale();

const selectedIndex = ref('xuqiu');

function setActiveItem(activeNumber: number) {
  items.value.setActiveItem(activeNumber);
}

// function scrollView(id: string) {
//   document.querySelector(`#${id}`)!.scrollIntoView({
//     behavior: "smooth"
//   });
// }
const emit = defineEmits(['item-click']);
function scrollView(id: string) {
  selectedIndex.value = id;
  emit('item-click', id);
}

function gotoAI(e) {
  if (e.target.getAttribute('class')!=='moreBoxWhite') router.push('/funPages/AI')
}


</script>

<style lang="scss" scoped>
.spacing__en {
  letter-spacing: .2rem !important;
}

.letter__en {
  font-size: 1.4rem;;
  margin-bottom: .4rem !important;
  word-wrap: break-word;
}
.pMore{
  // display: flex;
  margin-left: 58vw;
  margin-top: -6.5vh;
  
}
</style>

