<template>
    <div class="customer-page3">
        <div class="box" :class="{'box-en': lang === 'en'}" id="box1">
            <div class="left">
                <div class="title">{{ t(`${props.message.card1title}`) }}</div>
                <div class="content">
                    <div>{{ t(`${props.message.card1content}`) }}</div>
                    <div class="li1" v-if="t(`${props.message?.contentli1}`) !== 'undefined'">{{ t(`${props.message.contentli1}`) }}</div>
                    <div class="li2" v-if="t(`${props.message?.contentli2}`) !== 'undefined'">{{ t(`${props.message.contentli2}`) }}</div>
                    <div class="li3" v-if="t(`${props.message?.contentli3}`) !== 'undefined'">{{ t(`${props.message.contentli3}`) }}</div>
                </div>
                <div class="solve">{{ t('client.customerPage3.solve') }}</div>
                <ul>
                    <li>{{ t(`${props.message.card1li1}`) }}</li>
                    <li>{{ t(`${props.message.card1li2}`) }}</li>
                    <li v-if="t(`${props.message?.card1li3}`) !== 'undefined'">{{ t(`${props.message.card1li3}`) }}</li>
                    <li v-if="t(`${props.message?.card1li4}`) !== 'undefined'">{{ t(`${props.message.card1li4}`) }}</li>
                </ul>
            </div>
            <div class="right">
                <el-carousel :interval="5000" arrow="always" height="35.5rem" autoplay=false indicator-position="none" v-if="card === 'aotecar'">
                   <el-carousel-item> 
                      <img :src="require(`@/assets/imgs/aotecarli11_${lang}.jpg`)" alt="" style="height: 100%; width: 100%;"/>
                    </el-carousel-item>
                    <el-carousel-item> 
                      <img :src="require(`@/assets/imgs/aotecarli12_${lang}.jpg`)" alt="" style="height: 100%; width: 100%;"/>
                    </el-carousel-item>
                </el-carousel>
                <el-carousel :interval="5000" arrow="always" height="35.5rem" autoplay=false indicator-position="none" v-if="card === 'Fuerda'">
                   <el-carousel-item> 
                      <img :src="require(`@/assets/imgs/Fuerdali11_${lang}.jpg`)" alt="" style="height: 100%; width: 100%;"/>
                    </el-carousel-item>
                    <el-carousel-item> 
                      <img :src="require(`@/assets/imgs/Fuerdali12_${lang}.jpg`)" alt="" style="height: 100%; width: 100%;"/>
                    </el-carousel-item>
                </el-carousel>
                <el-carousel :interval="5000" arrow="always" height="35.5rem" autoplay=false indicator-position="none" v-if="card === 'TongyuAutomobile'">
                   <el-carousel-item> 
                      <img :src="require(`@/assets/imgs/TongyuAutomobileli11_${lang}.jpg`)" alt="" style="height: 100%; width: 100%;"/>
                    </el-carousel-item>
                    <el-carousel-item> 
                      <img :src="require(`@/assets/imgs/TongyuAutomobileli12_${lang}.jpg`)" alt="" style="height: 100%; width: 100%;"/>
                    </el-carousel-item>
                </el-carousel>
            </div>
        </div>
        <div class="box" :class="{'box-en': lang === 'en'}" id="box2">
            <div class="right">
              <el-carousel :interval="5000" arrow="always" height="35.5rem" autoplay=false indicator-position="none" v-if="card === 'aotecar'">
                   <el-carousel-item> 
                      <img :src="require(`@/assets/imgs/aotecarli21_${lang}.jpg`)" alt="" style="height: 100%; width: 100%;"/>
                    </el-carousel-item>
                    <el-carousel-item> 
                      <img :src="require(`@/assets/imgs/aotecarli22_${lang}.jpg`)" alt="" style="height: 100%; width: 100%;"/>
                    </el-carousel-item>
                </el-carousel>
                <el-carousel :interval="5000" arrow="always" height="35.5rem" autoplay=false indicator-position="none" v-if="card === 'Fuerda'">
                   <el-carousel-item> 
                      <img :src="require(`@/assets/imgs/Fuerdali21_${lang}.jpg`)" alt="" style="height: 100%; width: 100%;"/>
                    </el-carousel-item>
                    <el-carousel-item> 
                      <img :src="require(`@/assets/imgs/Fuerdali22_${lang}.jpg`)" alt="" style="height: 100%; width: 100%;"/>
                    </el-carousel-item>
                </el-carousel>
                <el-carousel :interval="5000" arrow="always" height="35.5rem" autoplay=false indicator-position="none" v-if="card === 'TongyuAutomobile'">
                   <el-carousel-item> 
                      <img :src="require(`@/assets/imgs/TongyuAutomobileli21_${lang}.jpg`)" alt="" style="height: 100%; width: 100%;"/>
                    </el-carousel-item>
                    <el-carousel-item> 
                      <img :src="require(`@/assets/imgs/TongyuAutomobileli22_${lang}.jpg`)" alt="" style="height: 100%; width: 100%;"/>
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div class="left">
                <div class="title">{{ t(`${props.message.card2title}`) }}</div>
                <div class="content">{{ t(`${props.message?.card2content}`) }}</div>
                <div class="solve">{{ t('client.customerPage3.solve') }}</div>
                <ul>
                    <li>{{ t(`${props.message.card2li1}`) }}</li>
                    <li>{{ t(`${props.message.card2li2}`) }}</li>
                </ul>
            </div>
        </div>
        <div class="box" :class="{'box-en': lang === 'en'}" id="box3">
            <div class="left">
                <div class="title">{{ t(`${props.message?.card3title}`) }}</div>
                <div class="content">{{ t(`${props.message?.card3content}`) }}</div>
                <div class="solve">{{ t('client.customerPage3.solve') }}</div>
                <ul>
                    <li>{{ t(`${props.message.card3li1}`) }}</li>
                    <li>{{ t(`${props.message.card3li2}`) }}</li>
                    <li v-if="t(`${props.message?.card3li3}`) !== 'undefined'">{{ t(`${props.message.card3li3}`) }}</li>
                </ul>
            </div>
            <div class="right">
              <el-carousel :interval="5000" arrow="always" height="35.5rem" autoplay=false indicator-position="none" v-if="card === 'aotecar'">
                   <el-carousel-item> 
                      <img :src="require(`@/assets/imgs/aotecarli31_${lang}.jpg`)" alt="" style="height: 100%; width: 100%;"/>
                    </el-carousel-item>
                    <el-carousel-item> 
                      <img :src="require(`@/assets/imgs/aotecarli32_${lang}.jpg`)" alt="" style="height: 100%; width: 100%;"/>
                    </el-carousel-item>
                </el-carousel>
                <el-carousel :interval="5000" arrow="always" height="35.5rem" autoplay=false indicator-position="none" v-if="card === 'Fuerda'">
                   <el-carousel-item> 
                      <img :src="require(`@/assets/imgs/Fuerdali31_${lang}.jpg`)" alt="" style="height: 100%; width: 100%;"/>
                    </el-carousel-item>
                    <el-carousel-item> 
                      <img :src="require(`@/assets/imgs/Fuerdali32_${lang}.jpg`)" alt="" style="height: 100%; width: 100%;"/>
                    </el-carousel-item>
                </el-carousel>
                <el-carousel :interval="5000" arrow="always" height="35.5rem" autoplay=false indicator-position="none" v-if="card === 'TongyuAutomobile'">
                   <el-carousel-item> 
                      <img :src="require(`@/assets/imgs/TongyuAutomobileli31_${lang}.jpg`)" alt="" style="height: 100%; width: 100%;"/>
                    </el-carousel-item>
                    <el-carousel-item> 
                      <img :src="require(`@/assets/imgs/TongyuAutomobileli32_${lang}.jpg`)" alt="" style="height: 100%; width: 100%;"/>
                    </el-carousel-item>
                </el-carousel>
            </div>
        </div>
        <!-- <div class="gotoNext">
            <router-link class="lastOne boxs" to="/customer_case/aotecar" v-if="card === 'TongyuAutomobile'">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-up" width="19"
                  height="16" viewBox="0 0 24 24" stroke-width="2" stroke="#2f64b5" fill="none" stroke-linecap="round"
                  stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <polyline points="9 1 0 12 9 23"/>
              </svg>
              {{ t("client.customerPage3.previous") }}
            </router-link>
            <router-link class="lastOne boxs" to="/customer_case/TongyuAutomobile" v-if="card === 'Fuerda'">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-up" width="25"
                  height="16" viewBox="0 0 24 24" stroke-width="2" stroke="#2f64b5" fill="none" stroke-linecap="round"
                  stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <polyline points="9 1 0 12 9 23"/>
              </svg>
              {{ t("client.customerPage3.previous") }}
            </router-link>

            <router-link class="nextOne boxs" to="/customer_case/TongyuAutomobile" v-if="card === 'aotecar'">
              {{ t("client.customerPage3.next") }}
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-up" width="20"
                  height="16" viewBox="0 0 24 24" stroke-width="2" stroke="#2f64b5" fill="none" stroke-linecap="round"
                  stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <polyline points="9 1 20 12 9 23"/>
              </svg>
            </router-link>
            <router-link class="nextOne boxs" to="/customer_case/Fuerda" v-if="card === 'TongyuAutomobile'">
              {{ t("client.customerPage3.next") }}
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-up" width="20"
                  height="16" viewBox="0 0 24 24" stroke-width="2" stroke="#2f64b5" fill="none" stroke-linecap="round"
                  stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <polyline points="9 1 20 12 9 23"/>
              </svg>
            </router-link>
        </div> -->
    </div>
</template>
<script setup lang="ts">
import useLocale from '@/hooks/useLocale' // 国际化hook
import { computed } from '@vue/reactivity';
import { useStore} from "vuex";
import {useRoute} from 'vue-router'

const route = useRoute();
import { ref,defineProps,watch,onMounted } from 'vue'

const store = useStore();
const lang = computed(() => store.getters.lang);
const {
  i18n: { t }, // 解构实例用具t 访问语言变量
} = useLocale()

const card = ref();

// 监视参数的变化
watch(() => route.params.pageId,(newPageId: any) => {
        // 在路由参数发生变化时执行的逻辑
        // 例如，可以重新加载数据或执行其他操作
        loadData(newPageId);
    }
)

// 参数变化时，需要做的事
const loadData = (newPageId: any) => {
    card.value = newPageId;
}

 // 在组件第一次加载时执行的逻辑
onMounted(() => {
      // 获取初始的 pageId
      const initialPageId = route.params.pageId;
      // 执行初始加载逻辑
      loadData(initialPageId);
    }
)


// 接受父组件传递的信息
const props = defineProps(['message']);

</script>

<style scoped>
.el-carousel__item h3 {
  color: #475669;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
  text-align: center;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>