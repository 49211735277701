<template>
  <div class="all" id="release">
    <TopDisplaybox
      :showTitle="`${t('homepage.publish.info')}`"
      :showBottom="`${t('homepage.publish.title')}`"
      :showText="`${t('homepage.publish.desc')}`"
    >
      <img src="../../assets/imgs/fabuicon.webp" alt="" />
    </TopDisplaybox>
    <div class="showImgrelease">
      <button class="btn" @click="toMapping">{{ t("btn.tryIt") }}</button>
      <img src="../../assets/imgs/fabumain_zh.webp" alt="" />
    </div>
  </div>
</template>

<script setup lang="ts">
import useLocale from "@/hooks/useLocale"; // 国际化hook
import { computed } from "@vue/reactivity";
import { useStore } from "vuex";
import TopDisplaybox from "@/components/Mobile/TopDisplaybox.vue";
const store = useStore();
const lang = computed(() => store.getters.lang);
const {
  i18n: { t }, // 解构实例用具t 访问语言变量
} = useLocale();
function toMapping(){
  window.open("https://ms.ytdevops.com")
}
</script>

<style lang="scss" scoped>
</style>

