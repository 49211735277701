<template>
  <page-template id="minjie">
    <template #header>
      <side-header>
        <template #heading-primary>MappingSpace</template>
        <template #heading-secondary>
          <div class="pageBtn">
            {{t('homepage.agile.info')}}
            <PageMore @click="gotoMJ" class="pMore" :message="'moreBox'"></PageMore>
          </div>
        </template>
        <template #heading-secondary2>{{t('homepage.agile.title')}}</template>
        <template #description>{{t('homepage.agile.desc')}}
          <div class="replace">
            <div class="title">{{ t("homepage.replace") }}</div>
            <div class="li1">
              <div class="li11">Jira</div>
            </div>
            <div class="li2">
              <div class="li22">{{ t("homepage.agile.li1") }}</div>
            </div>
          </div>
        </template>
      </side-header>
    </template>
    <template #default>
      <img :src="require(`@/assets/imgs/minjiemain_${lang}.webp`)" alt="" class="img">
    </template>
  </page-template>
</template>

<script setup lang="ts">
import useLocale from '@/hooks/useLocale' // 国际化hook
import { computed } from '@vue/reactivity';
import { useStore } from "vuex";
import PageMore from '@/pages/Pc/PageMore.vue'
import { useRouter } from 'vue-router';
const router = useRouter();

const store = useStore();
const lang = computed(() => store.getters.lang);
const {
  i18n: { t }, // 解构实例用具t 访问语言变量
} = useLocale()

function gotoMJ(e) {
  if (e.target.getAttribute('class')!=='moreBox')
    router.push('/funPages/Agile')
}

</script>

<style scoped>
.img{
  scale: 1.75!important;
  transform: translate(-10%, 20%);
}
.pageBtn{
  width: 105%;
  display: flex;
  justify-content: space-between;
  /* border: 1px solid #000; */
  align-items: center;
}
.pMore{
  z-index: 999;
}
</style>
