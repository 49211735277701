export const cardDataArr = {
  minjie: {
        "title0": 'minjie.title0',
        "word0":'minjie.word0',
        "imgUrl0":require('@/assets/imgs/minjie_pageImg1.png'),
        "title1": 'minjie.title1',
        "word1":`minjie.word1`,
        "imgUrl1": require('@/assets/imgs/minjie_func.png'),
        "title2": 'minjie.title2',
        "word2": [
            {
                "w1": 'minjie.word2.w1',
                "w2": 'minjie.word2.w2',
                "wUrl":require('@/assets/imgs/asp_img1.png')
            },
            {
               "w1": 'minjie.word2.w1',
                "w2": 'minjie.word2.w2',
                "wUrl":require('@/assets/imgs/asp_img2.png')
            },
            {
                "w1": 'minjie.word2.w1',
                "w2": 'minjie.word2.w2',
                "wUrl":require('@/assets/imgs/asp_img3.png')
            },
            
        ],
        "title3": "minjie.title3",
        "word3":`minjie.word3`,
        "imgUrl2":require('@/assets/imgs/minjie_pageImg2.png')     
  },
   vModel: {
        "title0": 'vModel.title0',
        "word0":'vModel.word0',
        "imgUrl0":require('@/assets/imgs/vModel_Pageimg1.png'),
        "title1": 'vModel.title1',
        "word1":`vModel.word1`,
        "imgUrl1": require('@/assets/imgs/vModel_Pageimg2.png'),
        "title2": 'vModel.title2',
        "word2": [
            {
                "w1": 'vModel.word2.w1',
                "w2": 'vModel.word2.w2',
                "wUrl":require('@/assets/imgs/vModel_Pageimg3.png')
            },
            {
               "w1": 'vModel.word2.w1',
                "w2": 'vModel.word2.w2',
                "wUrl":require('@/assets/imgs/vModel_Pageimg4.png')
            },
            {
                "w1": 'vModel.word2.w1',
                "w2": 'vModel.word2.w2',
                "wUrl":require('@/assets/imgs/vModel_Pageimg5.png')
            },
            
        ],
        "title3": "vModel.title3",
        "word3":`vModel.word3`,
        "imgUrl2":require('@/assets/imgs/vModel_Pageimg6.png')     
  },
   demand: {
        "title0": 'demand.title0',
        "word0":'demand.word0',
        "imgUrl0":require('@/assets/imgs/demand_pageImg1.png'),
        "title1": 'demand.title1',
        "word1":`demand.word1`,
        "imgUrl1": require('@/assets/imgs/demand_pageImg2.png'),
        "title2": 'demand.title2',
        "word2": [
            {
                "w1": 'demand.word2.w1',
                "w2": 'demand.word2.w2',
                "wUrl":require('@/assets/imgs/demand_pageImg3.png')
            },
            {
               "w1": 'demand.word2.w1',
                "w2": 'demand.word2.w2',
                "wUrl":require('@/assets/imgs/demand_pageImg4.png')
            },
            {
                "w1": 'demand.word2.w1',
                "w2": 'demand.word2.w2',
                "wUrl":require('@/assets/imgs/demand_pageImg5.png')
            },
            
        ],
        "title3": "demand.title3",
        "word3":`demand.word3`,
        "imgUrl2":require('@/assets/imgs/demand_pageImg6.png')     
  },
  ai: {
        "title0": 'ai.title0',
        "word0":'ai.word0',
        "imgUrl0":require('@/assets/imgs/ai_pageImg1.png'),
        "title1": 'ai.title1',
        "word1":`ai.word1`,
        "imgUrl1": require('@/assets/imgs/ai_pageImg2.png'),
        "title2": 'ai.title2',
        "word2": [
            {
                "w1": 'ai.word2.w1',
                "w2": 'ai.word2.w2',
                "wUrl":require('@/assets/imgs/ai_pageImg3.png')
            },
            {
               "w1": 'ai.word2.w1',
                "w2": 'ai.word2.w2',
                "wUrl":require('@/assets/imgs/ai_pageImg4.png')
            },
            {
                "w1": 'ai.word2.w1',
                "w2": 'ai.word2.w2',
                "wUrl":require('@/assets/imgs/ai_pageImg5.png')
            },
            
        ],
        "title3": "ai.title3",
        "word3":`ai.word3`,
        "imgUrl2":require('@/assets/imgs/ai_pageImg6.png')     
  },
  test: {
        "title0": 'test.title0',
        "word0":'test.word0',
        "imgUrl0":require('@/assets/imgs/test_pageImg1.png'),
        "title1": 'test.title1',
        "word1":`test.word1`,
        "imgUrl1": require('@/assets/imgs/test_pageImg2.png'),
        "title2": 'test.title2',
        "word2": [
            {
                "w1": 'test.word2.w1',
                "w2": 'test.word2.w2',
                "wUrl":require('@/assets/imgs/test_pageImg3.png')
            },
            {
               "w1": 'test.word2.w1',
                "w2": 'test.word2.w2',
                "wUrl":require('@/assets/imgs/test_pageImg4.png')
            },
            {
                "w1": 'test.word2.w1',
                "w2": 'test.word2.w2',
                "wUrl":require('@/assets/imgs/test_pageImg5.png')
            },
            
        ],
        "title3": "test.title3",
        "word3":`test.word3`,
        "imgUrl2":require('@/assets/imgs/test_pageImg6.png')     
  },
  baseLine: {
        "title0": 'baseLine.title0',
        "word0":'baseLine.word0',
        "imgUrl0":require('@/assets/imgs/baseLine_pageImg1.png'),
        "title1": 'baseLine.title1',
        "word1":`baseLine.word1`,
        "imgUrl1": require('@/assets/imgs/baseLine_pageImg2.png'),
        "title2": 'baseLine.title2',
        "word2": [
            {
                "w1": 'baseLine.word2.w1',
                "w2": 'baseLine.word2.w2',
                "wUrl":require('@/assets/imgs/baseLine_pageImg3.png')
            },
            {
               "w1": 'baseLine.word2.w1',
                "w2": 'baseLine.word2.w2',
                "wUrl":require('@/assets/imgs/baseLine_pageImg4.png')
            },
            {
                "w1": 'baseLine.word2.w1',
                "w2": 'baseLine.word2.w2',
                "wUrl":require('@/assets/imgs/baseLine_pageImg5.png')
            },
            
        ],
        "title3": "baseLine.title3",
        "word3":`baseLine.word3`,
        "imgUrl2":require('@/assets/imgs/baseLine_pageImg6.png')     
  }
};
