<template>
  <div class="priceall">
    <div class="homeBack">
      <div class="textone">{{ t("price.mid.title") }}</div>
      <div class="text">{{ t("price.mid.desc") }}</div>
    </div>
    <div class="main">
      <div class="main-top">
        <div class="main-top-one">
          <img src="../../assets/imgs/experience.png" alt="" />
          <div class="mian-text">{{ t("price.priceCard.version.free") }}</div>
        </div>
        <div class="main-top-two">
          {{ t("price.priceCard.onTrial.Trial1") }}
        </div>
      </div>
      <div class="main-mid"></div>
      <div class="main-mid-down">
        <button @click="toMapping">{{ t("btn.tryIt") }}</button>
      </div>
      <div class="main-bottom">
        <span class="main-bottom-top">{{
          t("price.priceCard.featureList.featureTitle")
        }}</span>
        <div class="main-bottom-top-one">
          <div>
            <div class="textposition">
              {{ t("price.priceCard.featureList.item1") }}
            </div>
            <span>{{ t("price.priceCard.cloud") }}</span>
          </div>
          <div>
            <div class="textposition">
              {{ t("price.priceCard.featureList.item2") }}
            </div>
            <span style="color: rgba(166, 166, 166, 1)">{{
              t("price.priceCard.userList.item1")
            }}</span>
          </div>
          <div>
            <div class="textposition">
              {{ t("price.priceCard.featureList.item3") }}
            </div>
            <span style="color: rgba(166, 166, 166, 1)">{{
              t("price.priceCard.notLimited")
            }}</span>
          </div>
          <div>
            <div class="textposition">
              {{ t("price.priceCard.featureList.item4") }}
            </div>
            <span style="color: rgba(166, 166, 166, 1)">{{
              t("price.priceCard.notLimited")
            }}</span>
          </div>
        </div>
        <div class="main-bottom-top-two">
          <div>
            <div class="textposition" style="color: rgba(42, 130, 228, 1)">
              {{ t("price.priceCard.featureList.item5") }}
            </div>
            <img class="yes" src="../../assets/imgs/yes.png" alt="" />
          </div>
          <div>
            <div
              class="textposition"
              style="
                color: rgba(42, 130, 228, 1);
                width: 17rem;
                position: relative;
                right: 4rem;
              "
            >
              {{ t("price.priceCard.featureList.item6") }}
            </div>
            <img
              class="yes"
              style="position: relative; left: -2.5rem"
              src="../../assets/imgs/yes.png"
              alt=""
            />
          </div>
          <div>
            <div class="textposition" style="color: rgba(42, 130, 228, 1)">
              {{ t("price.priceCard.featureList.item7") }}
            </div>
            <img class="yes" src="../../assets/imgs/yes.png" alt="" />
          </div>
          <div>
            <div class="textposition" style="color: rgba(42, 130, 228, 1)">
              {{ t("price.priceCard.featureList.item8") }}
            </div>
            <img class="yes" src="../../assets/imgs/yes.png" alt="" />
          </div>
          <div>
            <div class="textposition" style="color: rgba(42, 130, 228, 1)">
              {{ t("price.priceCard.featureList.item9") }}
            </div>
            <img class="yes" src="../../assets/imgs/yes.png" alt="" />
          </div>
        </div>
        <div class="main-mid" style="position: relative; top: 2rem"></div>
        <span class="main-bottom-top"></span>
        <div class="main-bottom-top-three">
          <div>
            <div class="textposition" style="color: rgba(42, 130, 228, 1)">
              {{ t("price.priceCard.projectList.item1") }}
            </div>
            <span>{{ t("price.priceCard.projectList.onePro") }}</span>
          </div>
          <div>
            <div class="textposition" style="color: rgba(42, 130, 228, 1)">
              {{ t("price.priceCard.projectList.item2") }}
            </div>
            <span>{{ t("price.priceCard.projectList.onePro") }}</span>
          </div>
          <div>
            <div class="textposition" style="color: rgba(42, 130, 228, 1)">
              {{ t("price.priceCard.projectList.item3") }}
            </div>
            <span>{{ t("price.priceCard.projectList.onePro") }}</span>
          </div>
          <div>
            <div class="textposition" style="color: rgba(42, 130, 228, 1)">
              {{ t("price.priceCard.projectList.item4") }}
            </div>
            <span>{{ t("price.priceCard.projectList.onePro") }}</span>
          </div>
          <div>
            <div class="textposition" style="color: rgba(42, 130, 228, 1)">
              {{ t("price.priceCard.projectList.item5") }}
            </div>
            <span>{{ t("price.priceCard.projectList.onePro") }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="main" style="margin-top: 4rem">
      <div class="main-top">
        <div class="main-top-one">
          <img
            src="../../assets/imgs/priceicon2.webp"
            style="width: 6rem; height: 6rem"
            alt=""
          />
          <div class="mian-text">
            {{ t("price.priceCard.version.enterprise") }}
          </div>
        </div>
        <div class="main-top-two">
          {{ t("price.priceCard.onTrial.Trial2") }}
        </div>
      </div>
      <div class="main-mid"></div>
      <div class="main-mid-down">
        <button @click="toMapping">{{ t("btn.tryIt") }}</button>
      </div>
      <div class="main-bottom">
        <span class="main-bottom-top">{{
          t("price.priceCard.featureList.featureTitle")
        }}</span>
        <div class="main-bottom-top-one">
          <div>
            <div class="textposition">
              {{ t("price.priceCard.featureList.item1") }}
            </div>
            <span>{{ t("price.priceCard.cloud") }}</span>
          </div>
          <div>
            <div class="textposition">
              {{ t("price.priceCard.featureList.item2") }}
            </div>
            <span>{{ t("price.priceCard.notLimited") }}</span>
          </div>
          <div>
            <div class="textposition">
              {{ t("price.priceCard.featureList.item3") }}
            </div>
            <span>{{ t("price.priceCard.notLimited") }}</span>
          </div>
          <div>
            <div class="textposition">
              {{ t("price.priceCard.featureList.item4") }}
            </div>
            <span>{{ t("price.priceCard.notLimited") }}</span>
          </div>
        </div>
        <div class="main-bottom-top-two">
          <div>
            <div class="textposition" style="color: rgba(42, 130, 228, 1)">
              {{ t("price.priceCard.featureList.item5") }}
            </div>
            <img class="yes" src="../../assets/imgs/yes.png" alt="" />
          </div>
          <div>
            <div
              class="textposition"
              style="
                color: rgba(42, 130, 228, 1);
                width: 17rem;
                position: relative;
                right: 4rem;
              "
            >
              {{ t("price.priceCard.featureList.item6") }}
            </div>
            <img
              style="position: relative; left: -2.5rem"
              class="yes"
              src="../../assets/imgs/yes.png"
              alt=""
            />
          </div>
          <div>
            <div class="textposition" style="color: rgba(42, 130, 228, 1)">
              {{ t("price.priceCard.featureList.item7") }}
            </div>
            <img class="yes" src="../../assets/imgs/yes.png" alt="" />
          </div>
          <div>
            <div class="textposition" style="color: rgba(42, 130, 228, 1)">
              {{ t("price.priceCard.featureList.item8") }}
            </div>
            <img class="yes" src="../../assets/imgs/yes.png" alt="" />
          </div>
          <div>
            <div class="textposition" style="color: rgba(42, 130, 228, 1)">
              {{ t("price.priceCard.featureList.item9") }}
            </div>
            <img class="yes" src="../../assets/imgs/yes.png" alt="" />
          </div>
        </div>
        <div class="main-mid" style="position: relative; top: 2rem"></div>
        <span class="main-bottom-top"></span>
        <div class="main-bottom-top-two">
          <div>
            <div class="textposition" style="color: rgba(42, 130, 228, 1)">
              {{ t("price.priceCard.projectList.item1") }}
            </div>
            <img class="yes" src="../../assets/imgs/yes.png" alt="" />
          </div>
          <div>
            <div
              class="textposition"
              style="
                color: rgba(42, 130, 228, 1);
                width: 17rem;
                position: relative;
                right: 4rem;
              "
            >
              {{ t("price.priceCard.projectList.item2") }}
            </div>
            <img
              style="position: relative; left: -2.5rem"
              class="yes"
              src="../../assets/imgs/yes.png"
              alt=""
            />
          </div>
          <div>
            <div class="textposition" style="color: rgba(42, 130, 228, 1)">
              {{ t("price.priceCard.projectList.item3") }}
            </div>
            <img class="yes" src="../../assets/imgs/yes.png" alt="" />
          </div>
          <div>
            <div class="textposition" style="color: rgba(42, 130, 228, 1)">
              {{ t("price.priceCard.projectList.item4") }}
            </div>
            <img class="yes" src="../../assets/imgs/yes.png" alt="" />
          </div>
          <div>
            <div class="textposition" style="color: rgba(42, 130, 228, 1)">
              {{ t("price.priceCard.projectList.item5") }}
            </div>
            <img class="yes" src="../../assets/imgs/yes.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="main" style="margin-top: 4rem">
      <div class="main-top">
        <div class="main-top-one">
          <img
            src="../../assets/imgs/priceicon3.webp"
            style="width: 5rem; height: 5rem"
            alt=""
          />
          <div class="mian-text">
            {{ t("price.priceCard.version.selfHosting") }}
          </div>
        </div>
        <div class="main-top-two">
          {{ t("price.priceCard.onTrial.Trial3") }}
        </div>
      </div>
      <div class="main-mid"></div>
      <div class="main-mid-down">
        <button @click="contactUs">{{ t("price.priceCard.contactUs") }}</button>
      </div>
      <div class="main-bottom">
        <img src="../../assets/imgs/priceicon4.webp" class="peoples" alt="" />
        <div class="tips">{{ t("price.priceCard.plz") }}</div>
        <div class="tips">{{ t("price.priceCard.private") }}</div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import useLocale from "@/hooks/useLocale"; // 国际化hook
import { computed } from "@vue/reactivity";
import { useStore } from "vuex";
import { ref } from "vue";
const store = useStore();
const lang = computed(() => store.getters.lang);
const {
  i18n: { t }, // 解构实例用具t 访问语言变量
} = useLocale();
function toMapping() {
  window.open("https://ms.ytdevops.com");
}
function contactUs() {
  document.querySelector(`#last`)!.scrollIntoView({
    behavior: "smooth",
    block: "end",
  });
}
</script>

<style lang="scss" scoped>
//.h__en{
//  max-width: 75rem;
//  font-size: 3.6rem !important;
//}

//.margin-top__en{
//  margin-top: 8.4rem !important;
//}../../assets/imgs/homeback.png
</style>

