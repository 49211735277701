<template>
    <div class="customer-page1">
        <div class="left">
            <div class="customerLogo">
                <a :href="props.message.href" target="_blank">
                    <img v-if="card === 'aotecar'" src="../../assets/imgs/carIndustry1.png" alt="">
                    <img style="margin-top: -3rem;" v-if="card === 'TongyuAutomobile'" src="../../assets/imgs/carIndustry2.png" alt="">
                    <img style="margin-top: -3rem;" v-if="card === 'Fuerda'" src="../../assets/imgs/FuerdaLogo.png" alt="">
                </a>
            </div>
            <div class="title" :class="{'title-en': lang === 'en'}">
                {{ t(`${props.message.title}`)}}
            </div>
            <div class="content" :class="{'content-en': lang === 'en'}">
                {{ t(`${props.message.content}`)}}
            </div>
            <div class="button">
                <a class="u-btn btn" :class="{'margin-top__en': lang==='en'}" href="https://ms.ytdevops.com" target="_blank" style="margin-right: 2.5rem;">{{t('btn.btnTrail')}}</a>
                <a @click="dialog_visible=true" class="u-btn u-btn--transparent" target="_blank">{{t('btn.demo')}}</a>
            </div>
        </div>
        <div class="right">
            <img v-if="card === 'aotecar'" src="../../assets/imgs/aotecarhome.png" alt="">
            <img v-if="card === 'TongyuAutomobile'" src="../../assets/imgs/TongyuAutomobilehome.png" alt="">
            <img v-if="card === 'Fuerda'" src="../../assets/imgs/Fuerdahome.png" alt="">
        </div>
    </div>
    <apply-dialog v-show="dialog_visible" :dialog_visible="dialog_visible" @close-dialog="closeDialog"></apply-dialog>
</template>
<script setup lang="ts">
import useLocale from '@/hooks/useLocale' // 国际化hook
import { computed } from '@vue/reactivity';
import { useStore } from "vuex";
import ApplyDialog from "@/pages/Pc/ApplyDialog.vue";
import { ref,defineProps,watch,onMounted } from 'vue'
import {useRoute} from 'vue-router'
const route = useRoute();

const store = useStore();
const lang = computed(() => store.getters.lang);


const card = ref();
const {
  i18n: { t }, // 解构实例用具t 访问语言变量
} = useLocale()

let dialog_visible = ref(false)

function closeDialog() {
  dialog_visible.value = false
}

// 监视参数的变化
watch(() => route.params.pageId,(newPageId: any) => {
        // 在路由参数发生变化时执行的逻辑
        // 例如，可以重新加载数据或执行其他操作
        loadData(newPageId);
    }
)

// 参数变化时，需要做的事
const loadData = (newPageId: any) => {
    card.value = newPageId;
}

 // 在组件第一次加载时执行的逻辑
 onMounted(() => {
      // 获取初始的 pageId
      const initialPageId = route.params.pageId;
      // 执行初始加载逻辑
      loadData(initialPageId);
    }
)

// 接受父组件传递的信息
const props = defineProps(['message']);
console.log(props.message.imgLogo);

</script>

<style scoped>

</style>