import { useI18n } from "vue-i18n";
import store from "../store";

export default function useLocale() {
    const i18n = useI18n();//实例化i18n
    // 切换语言
    const changeLocale = (value: string) => {
        store.commit("setLang", value)
        i18n.locale.value = value;//赋值切换语言
    }
    return {
        i18n,
        changeLocale
    }
}
