<template>
 <section class="home-page">
   <div class="main-content">
      <div class="mapping-space">
        <img src="../../assets/imgs/mapping-space.png" alt="">
      </div>
     <h2 class="h" >{{t('homepage.Jumbotron.word')}}</h2>
     <h2 class="h__2" :class="{'h__2-en': lang === 'en'}">{{t('homepage.Jumbotron.word2')}}</h2>
     <a class="u-btn btn" :class="{'margin-top__en': lang==='en'}" href="https://ms.ytdevops.com" target="_blank" style="margin-right: 4.8rem">{{t('btn.btnTrail')}}</a>
     <a @click="dialog_visible=true" class="u-btn u-btn--transparent" target="_blank">{{t('btn.demo')}}</a>
    <div class="carIndustry">{{ t('homepage.Jumbotron.carIndustry') }}</div>
    <div class="car-box">
      <div class="carleft carbutton">
        <img src="../../assets/imgs/carleft.png" alt="">
      </div>
      <div class="carIndustry1">
        <a href="http://www.aotecar.com/" target="_blank">
          <img src="../../assets/imgs/carIndustry1.png" alt="">
        </a>
      </div>
      <div class="carIndustry2">
        <a href="http://www.tongyuauto.com/" target="_blank">
          <img src="../../assets/imgs/carIndustry2.png" alt="">
        </a>
      </div>
      <div class="carIndustry3">
        <a href="https://www.chery.cn/" target="_blank">
          <img src="../../assets/imgs/carIndustry3.png" alt="">
        </a>
      </div>
      <div class="carIndustry4">
        <a href="http://www.leekr.cn/" target="_blank">
          <img src="../../assets/imgs/carIndustry4.png" alt="">
        </a>
      </div>
      <div class="carIndustry5">
        <a href="https://www.fuerda-china.com/" target="_blank">
          <img src="../../assets/imgs/carIndustry5.png" alt="">
        </a>
      </div>
      <div class="carright carbutton">
        <img src="../../assets/imgs/carright.png" alt="">
      </div>
    </div>
     <!-- <a :href="lang==='zh'?'https://uwafeanbnk.feishu.cn/docx/O2vTdjq0foexVixzKQmc7vX7nnd':'https://uwafeanbnk.feishu.cn/docx/G7Wddhw09oT319xWz2dcRHG5nSc'" class="u-btn u-btn--transparent" target="_blank">{{t('btn.demo')}}</a> -->
   </div>
   <div class="main-image" :class="{'main-image-en': lang === 'en'}">
     <!-- <img src="../../assets/imgs/car.png" alt=""> -->
     <img src="../../assets/imgs/shouyecard.png" alt="">
     <img v-show="lang === 'zh'" src="../../assets/imgs/shouyecard1-zh.png" alt="" >
     <img v-show="lang === 'en'" src="../../assets/imgs/shouyecard1-en.png" alt="" >
   </div>
 </section>
 <apply-dialog v-show="dialog_visible" :dialog_visible="dialog_visible" @close-dialog="closeDialog"></apply-dialog>

</template>

<script setup lang="ts">
import useLocale from '@/hooks/useLocale' // 国际化hook
import { computed } from '@vue/reactivity';
import { useStore} from "vuex";
import ApplyDialog from "@/pages/Pc/ApplyDialog.vue";
import { ref } from 'vue'

const store = useStore();
const lang = computed(() => store.getters.lang);
const {
  i18n: { t }, // 解构实例用具t 访问语言变量
} = useLocale()

let dialog_visible = ref(false)

function closeDialog() {
  dialog_visible.value = false
}

</script>

<style lang="scss" scoped>
//.h__en{
//  max-width: 75rem;
//  font-size: 3.6rem !important;
//}
//.margin-top__en{
//  margin-top: 8.4rem !important;
//}

</style>

