<template>
    <div class="turntable-card">
        <img class="center" src="../../assets/imgs/cut.png" alt="">
        <div class="wheel1 wheel" @click="selected('gitlab')">
            <div class="logo" v-if="displayGitlab()">
                <img :class="{'clickBig': change === 'gitlab'}" src="../../assets/imgs/gitlabLogo.png" alt="">
            </div>
        </div>
        <div class="wheel2 wheel"></div>
        <div class="wheel3 wheel"></div>
        <div class="wheel4 wheel"></div>
        <div class="wheel5 wheel" @click="selected('eolink')">
            <div class="logo" v-if="displayEolink()">
                <img :class="{'clickBig': change === 'eolink'}" src="../../assets/imgs/eolinkLogo.png" alt="">
            </div>
        </div>
        <div class="wheel6 wheel" @click="selected('feyshTechnology')">
            <div class="logo" v-if="displayfeyshTechnology()">
                <img :class="{'clickBig': change === 'feyshTechnology'}" src="../../assets/imgs/feyshTechnologyLogo.png" alt="">
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import {ref, defineProps,defineEmits} from 'vue';
// 用来判断是哪个logo显示
const props = defineProps(['message']);

// 显示eolink的Logo
const displayEolink = () => {
    if(props.message === 'SWArchitecture' || props.message === 'SWDetailedDesign' || props.message === 'coding' || props.message === 'SWUnitVerification' || props.message === 'SWIntegrationTest') {
        return true;
    } else {
        return false;
    }
}

// 显示极狐的Logo
const displayGitlab = () => {
    if(props.message === 'coding' || props.message === 'SWUnitVerification' || props.message === 'SWIntegrationTest' || props.message === 'SWQualificationTest' || props.message === 'systemIntegrationTest' || props.message === 'systemQualificationTest') {
        return true;
    } else {
        return false;
    }
}

// 显示蜚语科技的Logo
const displayfeyshTechnology = () => {
    if(props.message === 'coding' || props.message === 'SWUnitVerification') {
        return true;
    } else {
        return false;
    }
}

// 判断点击的Logo
const change = ref('');


const emit = defineEmits(['selected']);
// 传递给父组件的信息
const selected = (str: string) => {
    change.value = str;
    if(displayGitlab() === true && str === 'gitlab' || displayEolink() === true && str === 'eolink' || displayfeyshTechnology() === true && str === 'feyshTechnology') {
        emit('selected', str);
    }
}
</script>
