<template>
  <div class="page-other-img" style="background-color: #ffffff;padding-top: 15rem">
    <div  class="more">
      <img :src="require(`@/assets/imgs/process_title_${lang}.webp`)" alt="" />
      <PageMore @click="gotoMinjie"  :message="'moreBox'" class="pMore"></PageMore>
    </div>
    <img :src="require(`@/assets/imgs/process_${lang}.png`)" alt="" />
    <div class="more">
      <img :src="require(`@/assets/imgs/vmodel_title_${lang}.webp`)" alt="" />
      <PageMore @click="gotovModel"  :message="'moreBox'" class="pMore"></PageMore>
    </div>
    <img :src="require(`@/assets/imgs/vmodel_${lang}.webp`)" alt="" />
  </div>
</template>

<script setup lang="ts">
import useLocale from "@/hooks/useLocale"; // 国际化hook
import { computed } from "@vue/reactivity";
import { useStore } from "vuex";
import { useRouter } from 'vue-router';
 const router = useRouter();
import PageMore from '@/pages/Pc/PageMore.vue'
const store = useStore();
const lang = computed(() => store.getters.lang);
const {
  i18n: { t }, // 解构实例用具t 访问语言变量
} = useLocale();
function gotoMinjie(e) {
    if (e.target.getAttribute('class')!=='moreBox') router.push('/funPages/Agile')
}
function gotovModel(e) {
    if (e.target.getAttribute('class')!=='moreBox') router.push('/funPages/vModel')
}



</script>

<style scoped lang="scss">
.page-other-img {
  min-height: 90vh;
  text-align: center;
  background: linear-gradient(
    180deg,
    rgba(245, 245, 252, 1) 0%,
    rgba(245, 245, 252, 0) 100%
  );

  img {
    &:nth-of-type(odd) {
      width: 40%;
      margin-bottom: 68px;
    }
    &:nth-of-type(even) {
      width: 90%;
      margin-bottom: 134px;
    }
  }
  
  /* Ensure img styles inside .more div are applied correctly */
  .more img {
    width: 40%;
    margin-bottom: 68px;
  }
  
  /* Overriding the .more img styles when necessary to keep even/odd structure intact */
  .more + img {
    width: 90%;
    margin-bottom: 134px;
  }

  .more + img + .more img {
    width: 40%;
    margin-bottom: 68px;
  }

  .more + img + .more + img {
    width: 90%;
    margin-bottom: 134px;
  }
}

.more{
  display: flex;
  justify-content: center;
}
.pMore{
  // margin-top: 0.4rem;
  margin-left: 5rem
}

</style>
