<template>
  <section class="price-page1">
    <div class="img-box">
      <img src="../../assets/imgs/price1.webp" alt="">
    </div>
    <div class="header">
      <h2 :class="{'font__en': lang==='en'}">{{t('price.Jumbotron.title')}}</h2>
      <p>{{t('price.Jumbotron.desc')}}</p>
    </div>
    <div class="footer" :class="{'padding__en': lang==='en'}">
      <ul class="footer__menu" v-if="lang==='zh'" >
        <li>
          <div class="bar"></div>
          <p><span>满足</span><span class="normal">汽车</span><br><span class="normal">行业ASPICE标准</span></p>
        </li>
        <li>
          <div class="bar"></div>
          <p><span>超强</span><span class="normal">追溯性</span><br><span class="normal" style="letter-spacing: .85rem">高阶思维导图</span></p>
        </li>
        <li>
          <div class="bar"></div>
          <p><span>关联</span><span class="normal">透视图</span><br><span class="normal" style="letter-spacing: 1.5rem">覆盖度监测</span></p>
        </li>
        <li>
          <div class="bar"></div>
          <p><span>基线</span><span class="normal">管理</span><br><span class="normal" style="letter-spacing: .6rem">基线变更审计</span></p>
        </li>
        <li>
          <div class="bar"></div>
          <p><span>在线</span><span class="normal">评审</span><br><span class="normal" style="letter-spacing: .6rem">实时评审记录</span></p>
        </li>
      </ul>
      <ul class="footer__menu gap__en" v-if="lang==='en'">
        <li>
          <div class="bar"></div>
          <p><span class="strong">Meet ASPICE standards</span><span class="normal">For the automobile industry</span></p>
        </li>
        <li>
          <div class="bar"></div>
          <p><span class="strong">Ultra strong</span><span class="normal">In traceability via mind map</span></p>
        </li>
        <li>
          <div class="bar"></div>
          <p><span class="strong">Relationship diagram</span><span class="normal">Monitoring coverage</span></p>
        </li>
        <li>
          <div class="bar"></div>
          <p><span class="strong">Baseline management</span><span class="normal">Baseline change audit</span></p>
        </li>
        <li>
          <div class="bar"></div>
          <p><span class="strong">Online review</span><span class="normal" >Real-time record of review results</span></p>
        </li>
      </ul>
    </div>
  </section>
</template>


<script setup lang="ts">
import {defineExpose, ref} from "vue";

import useLocale from '@/hooks/useLocale' // 国际化hook
import { computed } from '@vue/reactivity';
import { useStore} from "vuex";
const store = useStore();
const lang = computed(() => store.getters.lang);
const {
  i18n: { t }, // 解构实例用具t 访问语言变量
} = useLocale()
</script>

<style scoped lang="scss">
.font__en{
  font-size: 5.6rem !important;
}
.strong{
  font-size: 1.6rem !important;
  margin-bottom: 1.6rem;
  display: block;
  &+.normal{
    font-size: 1.4rem!important;
  }
}
.padding__en{
  padding: 8rem 6rem !important; 
}
.gap__en{
  li{
    gap: 2.4rem !important;
  }
  .bar{
    height: 8rem !important;
  }
}
</style>
 
