<template>
  <home-page></home-page>
  <page-process></page-process>
  <cooperation-page @item-click="handleItemClick"></cooperation-page>
  <page-xuqiu v-if="selectedItem === 'xuqiu'"></page-xuqiu>
  <page-gongzuo v-if="selectedItem === 'gongzuo'"></page-gongzuo>
  <page-ceshi v-if="selectedItem === 'ceshi'"></page-ceshi>
  <page-jixian v-if="selectedItem === 'jixian'"></page-jixian>
  <page-biangeng v-if="selectedItem === 'biangeng'"></page-biangeng>
  <page-fabu v-if="selectedItem === 'fabu'"></page-fabu>
  <page-pingshen v-if="selectedItem === 'pingshen'"></page-pingshen>
  <page-wendang v-if="selectedItem === 'wendang'"></page-wendang>
  <page-minjie v-if="selectedItem === 'minjie'"></page-minjie>
  <page-case-resource></page-case-resource>
  <video-page></video-page>
</template>

<script setup lang="ts">
import HomePage from "@/pages/Pc/HomePage.vue";
import VideoPage from "@/pages/Pc/VideoPage.vue";
import CooperationPage from "@/pages/Pc/CooperationPage.vue";
import PageProcess from "@/pages/Pc/PageProcess.vue";
import PageXuqiu from "@/pages/Pc/PageXuqiu.vue";
import PageGongzuo from "@/pages/Pc/PageGongzuo.vue";
import PageCeshi from "@/pages/Pc/PageCeshi.vue";
import PageJixian from "@/pages/Pc/PageJixian.vue";
import PageBiangeng from "@/pages/Pc/PageBiangeng.vue";
import PageFabu from "@/pages/Pc/PageFabu.vue";
import PagePingshen from "@/pages/Pc/PagePingshen.vue";
import PageWendang from "@/pages/Pc/PageWendang.vue";
import PageMinjie from "@/pages/Pc/PageMinjie.vue";
import PageCaseResource from "@/pages/Pc/PageCaseResource.vue";


import { ref, nextTick } from "vue";
const selectedItem = ref("xuqiu");
const handleItemClick = (item: string) => {
  selectedItem.value = item;
  nextTick(() => {
    document.querySelector(`#${item}`)!.scrollIntoView({
      behavior: "smooth",
    });
  });
};
</script>
<script lang="ts">
export default {
  metaInfo: {
    meta: [
      {
        name: "keywords",
        content: "敏捷开发、V模型、需求管理、工作项管理、测试管理、基线管理、变更管理、发布管理、在线评审管理、文档管理、敏捷看板管理",
      },
      {
        name: "keywords",
        content: "Agile development、V-model、Requirement management、Task management、Test management、Baseline management、Change management、Publish management、Online review、Document management、Scrum & Kanban",
      },
      {
        name: "description",
        content: "MappingSpace是一站式需求、开发、测试管理平台，拥有九大管理场景，对敏捷开发流程支持和对V模型框架支持。",
      },
      {
        name: "description",
        content: "MappingSpace is a one-stop requirements,development,and testing management platform with nine management scenarios,supporting agile development processes and the V-model framework.",
      },
    ],
  },
};
</script>
