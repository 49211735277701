<template>
  <section class="page-about">
    <div class="cards">
      <content-card>
        <template #header>
          <p>8H Auto Devops</p>
          <h2>{{t('aboutUs.main.title')}}</h2>
          <div class="divider-bar"></div>
        </template>
        <template #main>
          <p class="des">{{t('aboutUs.main.desc')}}</p>
          <p class="scan">{{t('aboutUs.main.desc2')}}</p>
          <img :src="require(`@/assets/imgs/about1_${lang}.webp`)" alt="">
        </template>
      </content-card>
      <content-card2></content-card2>
      <content-card style="padding-left: 6.4rem;padding-right: 6.4rem">
        <template #header>
          <h2>{{t('aboutUs.recruiting.title')}}</h2>
          <div class="divider-bar"></div>
        </template>
        <template #main>
          <div class="content-box">
            <div class="row">
              <h3>{{t('aboutUs.recruiting.engineering')}}</h3>
              <div class="info-box">
                <div class="box">
                  <h3>{{t('aboutUs.recruiting.frontend')}}</h3>
                  <div class="img-box">
                <img src="../../assets/imgs/frontendicon.webp" alt="">
                  </div>
                </div>
                <a target="_blank" href="https://m-crecruit.liepin.com/job/share/detail-wx?sk=4b07155e0b88f6c7328f2765f6a4e7db&open_id=oKRge087a9jBkokZyB6rNI9b8_c8&nickname=%E5%BE%AE%E4%BF%A1%E7%94%A8%E6%88%B7&province=&sex=0&city=&country=&headimgurl=https://thirdwx.qlogo.cn/mmopen/vi_32/Q3auHgzwzM4wqqjLlPZib2zYNeiaAsSugD0NnaiamMIUFtpyyEQUps97RMZJyTCYwXY99Wu81OfR1sNOQfduZkucw/132&nonce=47083&timestamp=1661761198218&signature=4cf08d98eae30c9de710c1f52c68493633849456">{{t('btn.join')}}</a>
              </div>
              <div class="info-box">
                <div class="box">
                  <h3>{{t('aboutUs.recruiting.backend')}}</h3>
                  <div class="img-box">
                <img src="../../assets/imgs/backendicon.webp" alt="">
                  </div>
                </div>
                <a target="_blank" href="https://m-crecruit.liepin.com/job/share/detail-wx?sk=4b07155e0b88f6c7328f2765f6a4e7db&open_id=oKRge087a9jBkokZyB6rNI9b8_c8&nickname=%E5%BE%AE%E4%BF%A1%E7%94%A8%E6%88%B7&province=&sex=0&city=&country=&headimgurl=https://thirdwx.qlogo.cn/mmopen/vi_32/Q3auHgzwzM4wqqjLlPZib2zYNeiaAsSugD0NnaiamMIUFtpyyEQUps97RMZJyTCYwXY99Wu81OfR1sNOQfduZkucw/132&nonce=47083&timestamp=1661761198218&signature=4cf08d98eae30c9de710c1f52c68493633849456">{{t('btn.join')}}</a>
              </div>
            </div>
            <div class="row">
              <h3>{{t('aboutUs.recruiting.design')}}</h3>
              <div class="info-box">
                <div class="box">
                  <h3>{{t('aboutUs.recruiting.UI')}}</h3>
                  <div class="img-box">
                <img src="../../assets/imgs/uiuxicon.webp" alt="">
                  </div>
                </div>
                <a target="_blank" href="https://m-crecruit.liepin.com/job/share/detail-wx?sk=4b07155e0b88f6c7328f2765f6a4e7db&open_id=oKRge087a9jBkokZyB6rNI9b8_c8&nickname=%E5%BE%AE%E4%BF%A1%E7%94%A8%E6%88%B7&province=&sex=0&city=&country=&headimgurl=https://thirdwx.qlogo.cn/mmopen/vi_32/Q3auHgzwzM4wqqjLlPZib2zYNeiaAsSugD0NnaiamMIUFtpyyEQUps97RMZJyTCYwXY99Wu81OfR1sNOQfduZkucw/132&nonce=47083&timestamp=1661761198218&signature=4cf08d98eae30c9de710c1f52c68493633849456">{{t('btn.join')}}</a>
              </div>
              <div class="info-box">
                <div class="box">
                  <h3>{{t('aboutUs.recruiting.devops')}}</h3>
                  <div class="img-box">
                <img src="../../assets/imgs/devopsicon.webp" alt="">
                  </div>
                </div>
                <a target="_blank" href="https://m-crecruit.liepin.com/job/share/detail-wx?sk=4b07155e0b88f6c7328f2765f6a4e7db&open_id=oKRge087a9jBkokZyB6rNI9b8_c8&nickname=%E5%BE%AE%E4%BF%A1%E7%94%A8%E6%88%B7&province=&sex=0&city=&country=&headimgurl=https://thirdwx.qlogo.cn/mmopen/vi_32/Q3auHgzwzM4wqqjLlPZib2zYNeiaAsSugD0NnaiamMIUFtpyyEQUps97RMZJyTCYwXY99Wu81OfR1sNOQfduZkucw/132&nonce=47083&timestamp=1661761198218&signature=4cf08d98eae30c9de710c1f52c68493633849456">{{t('btn.join')}}</a>
              </div>
            </div>
          </div>
        </template>
      </content-card>
    </div>
    <div class="cooperation">
      <div class="header">
        <img src="../../assets/imgs/coicon1.webp" alt="">
        <span>{{t('aboutUs.cooperations')}}</span>
      </div>
      <div class="logo-box">
        <img src="../../assets/imgs/co1.png" alt="" style="height: 4.4rem;" @click="openWindow('https://www.eolink.com')">
        <img src="../../assets/imgs/co2.png" alt="" style="height: 4.8rem;" @click="openWindow('http://gitlab.cn')">
        <img src="../../assets/imgs/co8.png" alt="" style="margin-bottom: -.6rem" @click="openWindow('https://www.digiproto.com/')">
        <img src="../../assets/imgs/co4.png" alt="" style="height: 5.6rem; margin-bottom: -.6rem;" @click="openWindow('http://elephant-tech.cn')">
        <img src="../../assets/imgs/co6.png" alt="" style="height: 10rem; margin-bottom: -2.4rem" @click="openWindow('https://openoga.com/')">
        <img src="../../assets/imgs/co7.png" alt="" @click="openWindow('http://www.teststars.cc')">
      </div>
      <div class="logo-box" style="max-width: 120rem">
        <img src="../../assets/imgs/co5.png" alt="" style="height: 7.2rem; margin-bottom: -.8rem" @click="openWindow('http://hcis.com.cn')">
        <img src="../../assets/imgs/co3.png" alt="" style="height: 7.2rem; margin-bottom: -.8rem;" @click="openWindow('http://fmeamaster.com')">
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import ContentCard from "@/components/Pc/ContentCard.vue";
import ContentCard2 from "@/components/Pc/ContentCard2.vue";
import useLocale from '@/hooks/useLocale' // 国际化hook
import { computed } from '@vue/reactivity';
import { useStore} from "vuex";
function openWindow(url: string){
  window.open(url, "_blank")
}
const store = useStore();
const lang = computed(() => store.getters.lang);
const {
  i18n: { t }, // 解构实例用具t 访问语言变量
} = useLocale()
</script>

<style scoped lang="scss">

</style>

