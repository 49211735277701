<template>
  <section class="price-page2" style="position: relative">
    <div class="bg-img">
      <img src="../../assets/imgs/qiu.png" alt="" />
    </div>
    <div class="header">
      <h2 :class="{ font__en: lang === 'en' }">{{ t("price.mid.title") }}</h2>
      <p :class="{ 'font__en--small': lang == 'en' }">
        {{ t("price.mid.desc") }}
      </p>
    </div>
    <div class="cards">
      <div
        class="price-card-new price-card-new__free"
        :class="{ 'price-card-new__free__en': lang === 'en' }"
      >
        <div class="title">
          <span class="desp"
            ><img src="../../assets/imgs/priceicon1.webp" />{{
              t("price.priceCard.version.free")
            }}</span
          >
          <p class="shiyong">
            {{ t("price.priceCard.people") }}:
            {{ t("price.priceCard.userNumber.number1") }}
          </p>
          <div class="divider-bar"></div>
          <p class="price" :class="{ price__en: lang === 'en' }">
            <span class="strong"></span>
          </p>
        </div>
        <div class="bg-white">
          <div class="info">
            {{ t("price.priceCard.featureList.featureTitle") }}
          </div>
          <ul class="vip">
            <li>
              <div class="k">{{ t("price.priceCard.featureList.item1") }}:</div>
              <span class="v">{{ t("price.priceCard.cloud") }}</span>
            </li>
            <li>
              <div class="k" :class="{ k__en: lang === 'en' }">
                {{ t("price.priceCard.featureList.item2") }}:
              </div>
              <span class="v">100</span>
            </li>
            <li>
              <div class="k">{{ t("price.priceCard.featureList.item3") }}:</div>
              <span class="v">{{ t("price.priceCard.notLimited") }}</span>
            </li>
            <li style="margin-bottom: 0.4rem">
              <div class="k">{{ t("price.priceCard.featureList.item4") }}:</div>
              <span class="v">{{ t("price.priceCard.notLimited") }}</span>
            </li>
            <li>
              <div class="k__blue" :class="{ k__en: lang == 'en' }">
                <div class="k">
                  {{ t("price.priceCard.featureList.item5") }}
                </div>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon__right"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
            </li>
            <li>
              <span
                class="k__blue"
                :class="{ k__en: lang == 'en' }"
                v-html="t('price.priceCard.featureList.item6')"
              ></span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon__right"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
            </li>
            <li>
              <span class="k__blue" :class="{ k__en: lang == 'en' }">
                {{ t("price.priceCard.featureList.item7") }}</span
              >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon__right"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
            </li>
            <li>
              <span class="k__blue" :class="{ k__en: lang == 'en' }"
                >{{ t("price.priceCard.featureList.item8") }}
              </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon__right"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
            </li>
            <li>
              <span class="k__blue" :class="{ k__en: lang == 'en' }"
                >{{ t("price.priceCard.featureList.item9") }}
              </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon__right"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
            </li>
          </ul>
          <ul class="vip">
            <li>
              <div class="k__blue" :class="{ k__en: lang == 'en' }">
                <div class="k">
                  {{ t("price.priceCard.projectList.item1") }}
                </div>
              </div>
              <span>{{ t("price.priceCard.projectList.onePro") }}</span>
            </li>
            <li>
              <span
                class="k__blue"
                :class="{ k__en: lang == 'en' }"
                v-html="t('price.priceCard.projectList.item2')"
              ></span>
              <span>{{ t("price.priceCard.projectList.onePro") }}</span>
            </li>
            <li>
              <span class="k__blue" :class="{ k__en: lang == 'en' }">
                {{ t("price.priceCard.projectList.item3") }}</span
              >
              <span>{{ t("price.priceCard.projectList.onePro") }}</span>
            </li>
            <li>
              <span class="k__blue" :class="{ k__en: lang == 'en' }"
                >{{ t("price.priceCard.projectList.item4") }}
              </span>
              <span>{{ t("price.priceCard.projectList.onePro") }}</span>
            </li>
            <li>
              <span class="k__blue" :class="{ k__en: lang == 'en' }"
                >{{ t("price.priceCard.projectList.item5") }}
              </span>
              <span>{{ t("price.priceCard.projectList.onePro") }}</span>
            </li>
          </ul>
        </div>
        <div style="text-align: center; margin-top: 2.4rem">
          <a
            href="https://ms.ytdevops.com"
            target="_blank"
            class="u-btn u-btn--secondary u-btn--small"
            >{{ t("btn.tryIt") }}</a
          >
        </div>
      </div>
      <div class="price-card-new price-card-new__enterprise">
        <div class="label">{{ t("price.priceCard.hot") }}</div>
        <div class="title">
          <span class="desp" style="color: #ff922b; font-weight: 700"
            ><img
              style="height: 4.4rem"
              src="../../assets/imgs/priceicon2.webp"
            />{{ t("price.priceCard.version.enterprise") }}</span
          >
          <p class="shiyong">
            {{ t("price.priceCard.people") }}:
            {{ t("price.priceCard.userNumber.number2") }}
          </p>
          <div class="divider-bar"></div>
          <p
            class="price"
            :class="{ price__en: lang === 'en' }"
            style="height: 3.5rem"
          ></p>
        </div>
        <div class="bg-white" style="margin: 0 2rem">
          <div class="info">
            {{ t("price.priceCard.featureList.featureTitle") }}
          </div>
          <ul class="vip">
            <li>
              <div class="k">{{ t("price.priceCard.featureList.item1") }}:</div>
              <span class="v">{{ t("price.priceCard.cloud") }}</span>
            </li>
            <li>
              <div class="k">{{ t("price.priceCard.featureList.item2") }}:</div>
              <span class="v">{{ t("price.priceCard.notLimited") }}</span>
            </li>
            <li>
              <div class="k">{{ t("price.priceCard.featureList.item3") }}:</div>
              <span class="v">{{ t("price.priceCard.notLimited") }}</span>
            </li>
            <li style="margin-bottom: 0.4rem">
              <div class="k">{{ t("price.priceCard.featureList.item4") }}:</div>
              <span class="v">{{ t("price.priceCard.notLimited") }}</span>
            </li>
            <li>
              <div class="k__blue" :class="{ k__en: lang == 'en' }">
                <div class="k">
                  {{ t("price.priceCard.featureList.item5") }}
                </div>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon__right"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
            </li>
            <li>
              <span
                class="k__blue"
                :class="{ k__en: lang == 'en' }"
                v-html="t('price.priceCard.featureList.item6')"
              ></span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon__right"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
            </li>
            <li>
              <span class="k__blue" :class="{ k__en: lang == 'en' }">
                {{ t("price.priceCard.featureList.item7") }}</span
              >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon__right"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
            </li>
            <li>
              <span class="k__blue" :class="{ k__en: lang == 'en' }"
                >{{ t("price.priceCard.featureList.item8") }}
              </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon__right"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
            </li>
            <li>
              <span class="k__blue" :class="{ k__en: lang == 'en' }"
                >{{ t("price.priceCard.featureList.item9") }}
              </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon__right"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
            </li>
          </ul>
          <ul class="vip">
            <li>
              <div class="k__blue" :class="{ k__en: lang == 'en' }">
                <div class="k">
                  {{ t("price.priceCard.projectList.item1") }}
                </div>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon__right"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
            </li>
            <li>
              <span
                class="k__blue"
                :class="{ k__en: lang == 'en' }"
                v-html="t('price.priceCard.projectList.item2')"
              ></span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon__right"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
            </li>
            <li>
              <span class="k__blue" :class="{ k__en: lang == 'en' }">
                {{ t("price.priceCard.projectList.item3") }}</span
              >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon__right"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
            </li>
            <li>
              <span class="k__blue" :class="{ k__en: lang == 'en' }"
                >{{ t("price.priceCard.projectList.item4") }}
              </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon__right"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
            </li>
            <li>
              <span class="k__blue" :class="{ k__en: lang == 'en' }"
                >{{ t("price.priceCard.projectList.item5") }}
              </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon__right"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
            </li>
          </ul>
        </div>
        <div style="text-align: center; margin-top: 2.4rem">
          <a
            href="https://ms.ytdevops.com"
            target="_blank"
            class="u-btn u-btn--tertiary u-btn--small"
            >{{ t("btn.tryIt") }}</a
          >
        </div>
      </div>
      <div class="price-card-new price-card-new__private">
        <div class="title">
          <span class="desp"
            ><img src="../../assets/imgs/priceicon3.webp" />{{
              t("price.priceCard.version.selfHosting")
            }}</span
          >
          <p class="shiyong">
            {{ t("price.priceCard.people") }}:
            {{ t("price.priceCard.userNumber.number3") }}
          </p>
          <div class="divider-bar" style="margin-bottom: 6rem"></div>
        </div>
        <div
          class="bg-white height-equal"
          :class="{ 'height-equal__en': lang === 'en' }"
        >
          <div
            class="private-container margin-top"
            :class="{ 'margin-top__en': lang === 'en' }"
          >
            <img src="../../assets/imgs/priceicon4.webp" alt="" />
            <p>{{ t("price.priceCard.plz") }}</p>
            <p>{{ t("price.priceCard.private") }}</p>
          </div>
        </div>
        <div style="text-align: center; margin-top: 2.4rem">
          <a
            class="u-btn u-btn--secondary u-btn--small"
            @click="jumpToFooter"
            >{{ t("btn.contact") }}</a
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { onMounted } from "vue";

import useLocale from "@/hooks/useLocale"; // 国际化hook
import { computed } from "@vue/reactivity";
import { useStore } from "vuex";

let footer: HTMLElement;

const store = useStore();
const lang = computed(() => store.getters.lang);
const {
  i18n: { t }, // 解构实例用具t 访问语言变量
} = useLocale();

onMounted(() => {
  footer = document.querySelector("#footer")!;
});
function jumpToFooter() {
  footer.scrollIntoView({ behavior: "smooth" });
}
</script>

<style scoped lang="scss">
.decorate {
  display: inline-block;
  position: relative;
  &::before {
    position: absolute;
    content: "";
    width: 3.2rem;
    height: 1px;
    background: #333;
    top: 50%;
    left: 1.6rem;
    transform: translateY(-50%);
  }
}
.small {
  transform: scale(0.8);
}
.mianfei {
  color: rgba(37, 92, 181, 1);
  letter-spacing: 1rem;
}
.rotate {
  font-size: 2.4rem;
  color: #777;
}
.private-contact {
  padding: 3.6rem 3.2rem;
  p {
    font-size: 2.8rem;
    color: #777;
  }
  .p2 {
    margin-left: 4.8rem;
    margin-top: -2.4rem;
  }
}

.font__en {
  max-width: 80rem;
  text-align: center;
  font-size: 3.2rem !important;

  &--small {
    font-size: 2rem !important;
  }
}
.price__en {
  letter-spacing: 0rem !important;
}
.k__en {
  text-align: right !important;
}
.height-equal {
  min-height: 50rem;

  &__en {
    min-height: 56.5rem;
  }
}
.margin-top {
  margin-top: 6.4rem;
  &__en {
    margin-top: 12rem;
  }
}
</style>
