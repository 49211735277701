<template>
  <MbAboutHome></MbAboutHome>
</template>

<script setup lang="ts">
import MbAboutHome from "@/pages/Mobile/MbAboutHome.vue";

</script>
<script lang="ts">
export default {
  metaInfo: {
    meta: [
    {
        name: 'keywords',
        content: '数字化转型、企业数字化、云端DevOps产品、真诚、协作、远见、自驱'
      },
      {
        name: 'keywords',
        content: 'digital transformation、The digitalization of enterprises、cloud DevOps products、Honesty、Collaboration、Vision、Self-driven'
      },
      {
        name: 'description',
        content: '云体科技是一家诞生于汽车行业的科技公司，专注于开发面向汽车行业的云端DevOps产品，以此帮助车企快速数字化转型。',
      },
      {
        name: 'description',
        content: 'Suzhou Yunti Technology Co.,Ltd.,a technology company based in the auto industry,focuses on developing cloud DevOps products for the industry,aiming to help auto companies complete their digital transformation rapidly.',
      },
    ],
  }
}
</script>
